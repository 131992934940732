import { useAuth } from "@auth";

export function SaveContact(theID, theJSON) {
  const { authResultPayload } = useAuth();
  const sftoken = authResultPayload["http://ggwcup.com/sftoken"];

  console.log("theJSON= ", theJSON);
  console.log("Tokem= ", sftoken);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + sftoken);

  var raw = JSON.stringify(theJSON);

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
      theID,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}

export async function GetUser() {
  const { authResultPayload } = useAuth();
  const sftoken = authResultPayload["http://ggwcup.com/sftoken"];
  const sfauth0 = authResultPayload["sub"];

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + sftoken);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/Auth0ID__c/" +
      sfauth0,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => console.log("TheUser= ", result))
    .catch((error) => console.log("error", error));

  return response;
}
