import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "react-avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Timeline } from "react-twitter-widgets";
import { useAuth } from "@auth";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { notifyMonday } from "@lib";
import TableHead from "@mui/material/TableHead";
import { format, parseJSON } from "date-fns";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { setProgramInStore } from "@redux/programSlice";
import { setUserInStore } from "@redux/userSlice";
import { setMembersInStore } from "@redux/membersSlice";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

const NiceBox = styled(Box)({
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "80%",
  margin: "10px",
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
  border: "none",
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "team_name",
    numeric: false,
    disablePadding: false,
    label: "Team",
  },
  {
    id: "action_p",
    numeric: true,
    disablePadding: false,
    label: "A",
  },
  {
    id: "style_p",
    numeric: true,
    disablePadding: false,
    label: "S",
  },
  {
    id: "spirit_p",
    numeric: true,
    disablePadding: false,
    label: "P",
  },
  {
    id: "game_p",
    numeric: true,
    disablePadding: false,
    label: "F",
  },
  {
    id: "crowd_p",
    numeric: true,
    disablePadding: false,
    label: "C",
  },
  {
    id: "total_p",
    numeric: true,
    disablePadding: false,
    label: "T",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ m: 0, p: 0, pb: 1 }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function Home() {
  //CONSTANTS
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const eirSF = useSelector((state) => state.credential);
  const eirUser = useSelector((state) => state.user);
  const eirProgram = useSelector((state) => state.program);
  const eirMembers = useSelector((state) => state.members);

  const sftoken = sessionStorage.getItem("sftoken");
  const sfid = sessionStorage.getItem("sfid");
  const userProgram = sessionStorage.getItem("userProgram");
  const userTeam = sessionStorage.getItem("userTeam");

  //STATES
  const [open, setOpen] = useState();
  const [dashboardActionData, setDashboardActionData] = useState(true);
  const [showActionData, setShowActionData] = useState(false);
  const [showTeamProfile, setShowTeamProfile] = useState(false);
  const [showTodos, setShowTodos] = useState(false);
  const [showRanking, setShowRanking] = useState(false);
  const [values, setValues] = useState([]);
  const [teamvalue, setTeamvalue] = useState([]);
  const [members, setMembers] = useState([]);
  const [bulletins, setBulletins] = useState([]);
  const [todos, setTodos] = useState([]);
  const [todosAR, setTodosAR] = useState([]);
  const [programEvents, setProgramEvents] = useState([]);
  const [programRankings, setProgramRankings] = useState([]);
  const [events, setEvents] = useState([]);
  const [program, setProgram] = useState([]);
  const [teamAR_Q4_hours, setTeamAR_Q4_hours] = useState(0);
  const [teamAR_Q7_observers, setTeamAR_Q7_observers] = useState(0);
  const [teamAR_Q14_actions, setTeamAR_Q14_actions] = useState(0);
  const [teamAR_Q18_people, setTeamAR_Q18_people] = useState(0);
  const [programAR_Q4_hours, setProgramAR_Q4_hours] = useState(0);
  const [programAR_Q7_observers, setProgramAR_Q7_observers] = useState(0);
  const [programAR_Q14_actions, setProgramAR_Q14_actions] = useState(0);
  const [programAR_Q18_people, setProgramAR_Q18_people] = useState(0);
  const [appReviwer, setAppReviewer] = useState(false);
  const [openPlayerPreview, setOpenPlayerPreview] = useState(false);
  const [valuesPlayerPreview, setValuesPlayerPreview] = useState([]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("total_p");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function viewPlayer(theID) {
    GetPlayer(theID);
    setOpenPlayerPreview(true);
  }

  const togglePlayerPreview = (newOpen) => () => {
    setOpenPlayerPreview(newOpen);
  };

  useEffect(() => {
    FetchUser();
    setShowRanking(false);
    //Detroit 24
    if (userProgram === '0019N000005Go40QAC') {
      setShowRanking(true);
    }
    //League 23
    if (userProgram === '0019N000004MyByQAK') {
      setShowRanking(true);
    }
    //League 22
    if (userProgram === '0019N000002iB9vQAE') {
      setShowRanking(true);
    }
  }, []);

  //FUNCTIONS
  function getQuestionValue(rawData, theQuestion) {
    var scanData = rawData;
    var theValue = 0;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.EIR_Action_Question__c === theQuestion) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    if (scanData.length === 1) {
      theValue = scanData[0].expr0;
    }
    return theValue;
  }

  function doTodoAR(theID, theReport) {
    if (theReport === "a2C09000000I5IREA0") {
      navigate("/weekly-coach?id=" + theID);
    }
    if (theReport === "a2C9N0000000HLBUA2") {
      navigate("/ar-league-captain?id=" + theID);
    }
    if (theReport === "a2C9N0000002TUMUA2") {
      navigate("/ar-impact-league-captain?id=" + theID);
    }
    if (theReport === "a2C9N0000002TURUA2") {
      navigate("/ar-impact-league-sponsor?id=" + theID);
    }
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  function handleTeamProfilePath() {
    if (values.Is_App_Data_Manager__c) {
      navigate("/team?id=" + userTeam);
    } else {
      FetchTeam(userTeam);
      setOpen(true);
    }
  }

  function handleRSVP(theRow) {
    return (event) => {
      SaveRSVP(theRow.Id, event.target.value, theRow.Invitee__c);
    };
  }

  function SaveRSVP(theId, theValue, theUser) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var thePayload = {
      RSVP__c: theValue,
    };

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(thePayload, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Invitation__c/" +
        theId,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("RSVP saved"))
      .then((result) => FetchInvitations(theUser))
      .catch((error) => console.log("error", error));
  }

  function updateAvatar() {
    //console.log("Goto avatar");
    navigate("/avatar?id=" + values.Id + "&src=" + values.Avatar__c);
  }

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }
  // MISC
  const showGuard = () => {
    sessionStorage.removeItem("sfid");
    sessionStorage.removeItem("sftoken");
    //setAuthenticated(false);
    navigate("/guard");
  };

  //FETCH CALLS
  const FetchTeam = async (theTeamId) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Account/" +
        theTeamId,
      requestOptions
    );
    const data = await response.json();
    try {
      setTeamvalue(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchMembers = async (theTeam) => {
    //console.log("eirMembers and NOT InStore=", eirMembers);
    //const theURL = "http://localhost:5000/teamMembers/" + theTeam;
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/teamMembers/" + theTeam;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setMembers(data);
    } catch (err) {
      console.log(err);
    }
  };

  const GetPlayer = async (theID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
        theID,
      requestOptions
    );
    const data = await response.json();
    try {
      setValuesPlayerPreview(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchARTodos = async (theUser, theTeam) => {
    theUser = theUser.substr(0, 15);
    var myHeaders = new Headers();
    var sqol = "select id";
    sqol = sqol + ", Title__c";
    sqol = sqol + ", Start_Date__c";
    sqol = sqol + ", EIR_Action_Report__c	";
    sqol = sqol + ", Due_Date__c";
    sqol = sqol + " from EIR_Action_Todo__c";
    sqol = sqol + " where App_Data_Manager__c = '" + theUser + "'";
    sqol = sqol + " and Team__c = '" + theTeam + "'";
    sqol = sqol + " and Program__c = '" + userProgram + "'";
    sqol = sqol + " and State__c = 'Open'";
    sqol = sqol + " ORDER BY Start_Date__c";
    //console.log("sqol= ", sqol);

    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      //console.log("ActionReportTodos= ", data);
      setTodosAR(data.records);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchBulletins = async () => {
    var myHeaders = new Headers();
    var sqol = "select id";
    sqol = sqol + ", Headline__c";
    sqol = sqol + ", Asset__c";
    sqol = sqol + ", Type__c";
    sqol = sqol + ", Release_Date__c";
    sqol = sqol + " from Media__c";
    sqol = sqol + " where Show_in_portal_library__c = True";
    sqol = sqol + " and (Type__c = 'News' or Type__c = 'Press Release')";
    sqol = sqol + " ORDER BY Release_Date__c DESC";
    sqol = sqol + " LIMIT 5";
    //console.log("sqol= ", sqol);

    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      setBulletins(data.records);
    } catch (err) {
      console.log(err);
    }
  };

  //For the user across all teams
  const FetchOpenSurveys = async (theID) => {
    var sqol = "select id";
    sqol = sqol + ", ArdiraSurvey__Due_Date__c";
    sqol = sqol + ", ArdiraSurvey__Response_URL__c";
    sqol = sqol + ", ArdiraSurvey__Status__c";
    sqol = sqol + ", ArdiraSurvey__Survey_Name__c";
    sqol = sqol + ", OwnerId";
    sqol = sqol + " from ArdiraSurvey__Survey_Result__c";
    sqol = sqol + " where ArdiraSurvey__Target_Record_Id__c = '" + theID + "'";
    sqol = sqol + " and (ArdiraSurvey__Status__c = 'In Progress'";
    // sqol = sqol + " or ArdiraSurvey__Status__c = 'Completed'";
    sqol = sqol + " or ArdiraSurvey__Status__c = 'Assigned/Dispatched')";
    //console.log("sqol= ", sqol);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      if (userProgram !== "0019N000002iB9vQAE") {
        setTodos(data.records);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //For the active team
  const FetchTeamActionReportTotals = async (theID) => {
    //ECJ G#2 - Red = entesar ibrahem
    //theID = "00109000017UCzkAAG";
    theID = theID.substr(0, 15);
    var sqol = "select EIR_Action_Question__c, SUM(Value_as_Number__c)";
    sqol = sqol + " from EIR_Action_Response__c";
    sqol = sqol + " where Team_ID__c = '" + theID + "'";
    sqol = sqol + " GROUP BY EIR_Action_Question__c";
    //console.log("sqol= ", sqol);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      setTeamAR_Q4_hours(
        getQuestionValue(data.records, "a2D090000004OVNEA2") / 60
      );
      setTeamAR_Q7_observers(
        getQuestionValue(data.records, "a2D090000004OVTEA2")
      );
      setTeamAR_Q14_actions(
        getQuestionValue(data.records, "a2D090000004OVcEAM")
      );
      setTeamAR_Q18_people(
        getQuestionValue(data.records, "a2D090000004OVDEA2") +
          getQuestionValue(data.records, "a2D090000004OVhEAM")
      );
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgram = async (theID) => {
    //console.log("dashboardActionData2=", dashboardActionData);

    //if (eirProgram.programInStore ) {
    if (false) {
      //console.log("eirProgram and InStore=", eirProgram);
      setProgram(eirProgram);
      setShowActionData(eirProgram.App_Show_Action_Data__c);
      setShowTeamProfile(eirProgram.App_Show_Team_Profile__c);
    } else {
      //console.log("eirProgram and NOT InStore=", eirProgram);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + sftoken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Account/" +
          theID,
        requestOptions
      );
      const data = await response.json();
      try {
        setProgram(data);
        if (data.Program_Type__c === "Coach Program") {
          setShowTodos(true);
        }
        //console.log(data);
        setShowActionData(data.App_Show_Action_Data__c);
        setShowTeamProfile(data.App_Show_Team_Profile__c);
        dispatch(setProgramInStore(data));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const FetchProgramActionReportTotals = async (theID, isReviewer) => {
    //Coach Program Jordan 2022
    if (isReviewer) {
      theID = "00109000017UBveAAG";
    }
    //theID = theID.substr(0, 15);
    theID = "xxxxxxxx";
    var sqol = "select EIR_Action_Question__c, SUM(Value_as_Number__c)";
    sqol = sqol + " from EIR_Action_Response__c";
    sqol = sqol + " where Program_ID__c = '" + theID + "'";
    sqol = sqol + " GROUP BY EIR_Action_Question__c";
    //console.log("sqol= ", sqol);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,
      requestOptions
    );
    const data = await response.json();
    try {
      setProgramAR_Q4_hours(
        getQuestionValue(data.records, "a2D090000004OVNEA2") / 60
      );
      setProgramAR_Q7_observers(
        getQuestionValue(data.records, "a2D090000004OVTEA2")
      );
      setProgramAR_Q14_actions(
        getQuestionValue(data.records, "a2D090000004OVcEAM")
      );
      setProgramAR_Q18_people(
        getQuestionValue(data.records, "a2D090000004OVDEA2") +
          getQuestionValue(data.records, "a2D090000004OVhEAM")
      );
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramRanking = async (Id) => {
    //const theURL = "http://localhost:5000/teamranking/" + Id;
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/teamranking/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramRankings(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramEvents = async (Id) => {
    //const theURL = "http://localhost:5000/userEvents/" + Id;
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/userEvents/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramEvents(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchUser = async () => {
    //console.log("eirUser and NOT inStore=", eirUser);
    var sqol = "select id";
    sqol = sqol + ", AccountId";
    sqol = sqol + ", Access_to_App__c";
    sqol = sqol + ", App_Reviewer__c";
    sqol = sqol + ", Name";
    sqol = sqol + ", UserProfile__c";
    sqol = sqol + ", Email";
    sqol = sqol + ", MobilePhone";
    sqol = sqol + ", Global_Goal__r.Name";
    sqol = sqol + ", Global_Goal__c";
    sqol = sqol + ", Play_Philosophy__c";
    sqol = sqol + ", Program__c";
    sqol = sqol + ", Program_Id__c";
    sqol = sqol + ", Avatar__c";
    sqol = sqol + ", Show_details_on_GGWCUP__c";
    sqol = sqol + ", Bio__c";
    sqol = sqol + ", npsp__Primary_Affiliation__c";
    sqol = sqol + ", Affiliated_Team__c";
    sqol = sqol + ", Is_App_Data_Manager__c";
    sqol = sqol + " from Contact";
    sqol = sqol + " where id = '" + sfid + "'";
    //sqol = sqol + " where Auth0_Email__c = 'fathiakarbosh@gmail.com'";

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" + sqol,

      requestOptions
    );
    const rawdata = await response.json();
    const data = rawdata.records[0];
    try {
      setValues(data);
      dispatch(setUserInStore(data));
      sessionStorage.setItem("userId", data.Id);
      sessionStorage.setItem("userEmail", data.Email);
      sessionStorage.setItem("userName", data.Name);
      sessionStorage.setItem("userType", data.UserProfile__c);
      sessionStorage.setItem("userDataManager", data.Is_App_Data_Manager__c);
      sessionStorage.setItem("userReviewer", data.App_Reviewer__c);
      setAppReviewer(data.App_Reviewer__c);
      FetchMembers(userTeam);
      FetchOpenSurveys(data.Id);
      FetchInvitations(data.Id);
      FetchARTodos(data.Id, userTeam);
      FetchProgram(userProgram);
      FetchProgramEvents(userProgram);
      FetchProgramRanking(userProgram);
      FetchTeam(userTeam);
      FetchTeamActionReportTotals(userTeam);
      FetchProgramActionReportTotals(data.Program_Id__c, data.App_Reviewer__c);
      //console.log("All fetch are defined");
    } catch (err) {
      console.log(err);
    }
  };

  const FetchInvitations = async (theContactID) => {
    var sqol = "select Id";
    sqol = sqol + ", Invitee__c";
    sqol = sqol + ", Type__c";
    sqol = sqol + ", Name";
    sqol = sqol + ", Date_and_Time__c";
    sqol = sqol + ", Training_Event_Name__c";
    sqol = sqol + ", Training_Event_Type__c";
    sqol = sqol + ", Training_event__c";
    sqol = sqol + ", RSVP__c";
    sqol = sqol + " from Invitation__c";
    sqol = sqol + " where Invitee__c = '" + theContactID + "'";
    sqol = sqol + " and Date_and_Time__c >= TODAY";
    sqol = sqol + " ORDER BY Date_and_Time__c ASC";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://eirorg2.my.salesforce.com/services/data/v21.0/query/?q=" +
          sqol,
        requestOptions
      );
      const data = await response.json();
      setEvents(data.records);
    } catch (err) {
      notifyMonday(err, "FetchInvitations()");
      console.log(err);
    }
  };

  //RENDER
  return (
    <AuthenticatedLayout title="Action Board">
      <div>
        <Drawer
          PaperProps={{
            sx: {
              width: {
                xs: "100%",
                sm: "100%",
                md: "66%",
                xl: "66%",
              },
            },
          }}
          anchor="right"
          open={openPlayerPreview}
          onClose={togglePlayerPreview(false)}
          onOpen={togglePlayerPreview(true)}
        >
          <Container>
            {" "}
            <Box
              sx={{ pt: 2, pr: 2 }}
              display="flex"
              justifyContent="space-between"
            >
              <Typography align="left" variant="h4">
                {valuesPlayerPreview.Name || ""}
              </Typography>

              <CloseIcon
                fontSize="large"
                align="right"
                onClick={togglePlayerPreview(false)}
              />
            </Box>
            <form>
              <Grid align="left" container spacing={2}>
                <Grid item>
                  <div>
                    <div>
                      <Box sx={{ p: 1, pt: 3 }}>
                        <Avatar
                          name={valuesPlayerPreview.Name || "XX XX"}
                          size="150"
                          src={
                            "https://agxpxlknzr.cloudimg.io/" +
                            valuesPlayerPreview.Avatar__c +
                            "?width=150&height=150"
                          }
                          color={Avatar.getRandomColor("sitebase", [
                            "red",
                            "blue",
                          ])}
                        />
                      </Box>
                    </div>
                    <Typography
                      sx={{ p: 1 }}
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      PERSONAL DETAILS
                    </Typography>
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-role"
                      label="Role"
                      value={valuesPlayerPreview.UserProfile__c || ""}
                      variant="standard"
                    />
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-team"
                      label="Team"
                      value={valuesPlayerPreview.Affiliated_Team__c || ""}
                      variant="standard"
                    />
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-firstname"
                      label="First Name"
                      value={valuesPlayerPreview.FirstName || ""}
                      name="FirstName"
                      variant="standard"
                    />
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-lastname"
                      label="Last Name"
                      value={valuesPlayerPreview.LastName || ""}
                      name="LastName"
                      variant="standard"
                    />
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-email"
                      label="Email"
                      value={valuesPlayerPreview.Email || ""}
                      name="Email"
                      variant="standard"
                    />
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-phone"
                      label="Mobile Phone"
                      value={valuesPlayerPreview.MobilePhone || ""}
                      name="MobilePhone"
                      variant="standard"
                    />
                    <NiceCardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={flipGG(valuesPlayerPreview.Global_Goal__c)}
                      alt="GGWCUP Ball"
                    />
                    <Typography
                      sx={{ p: 1 }}
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      OTHER DETAILS
                    </Typography>
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-bio"
                      label="Bio"
                      value={valuesPlayerPreview.Bio__c || ""}
                      name="Bio__c"
                      multiline
                      rows={4}
                      variant="standard"
                    />
                    <NiceTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="profile-philosophy"
                      label="Play Philosophy"
                      value={valuesPlayerPreview.Play_Philosophy__c || ""}
                      name="Play_Philosophy__c"
                      multiline
                      rows={4}
                      variant="standard"
                    />
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Drawer>
        <Drawer
          PaperProps={{
            sx: { width: "66%" },
          }}
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{ pt: 2, pr: 2 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
              Team
            </Typography>
            <CloseIcon
              fontSize="large"
              align="right"
              onClick={() => setOpen(false)}
            />
          </Box>
          <NiceTextField
            id="team-name"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="Team Name"
            value={teamvalue.Name || ""}
          />
          <NiceTextField
            id="team-bio"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="Team Bio"
            value={teamvalue.Introduce_the_team_club__c || ""}
          />
          <NiceCardMedia
            component="img"
            sx={{ width: 151 }}
            image={flipGG(teamvalue.Global_Goal__c)}
            alt="GGWCUP Ball"
          />
          <NiceTextField
            id="team-ggwhy"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="Why is this Goal important to the Team"
            value={teamvalue.Global_Goal_Why__c || ""}
          />
          <NiceTextField
            id="team-ggaction"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="How will the Team take action for the Goal?"
            value={teamvalue.Global_Goal_Action__c || ""}
          />
          <NiceTextField
            id="team-ggimpact"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label="How will the Team measure impact and reach?"
            value={teamvalue.Global_Goal_Impact__c || ""}
          />
        </Drawer>
      </div>
      <Container>
        <NiceBox sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sx={{ display: { xs: "none", md: "flex" } }}>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <MenuList align="left">
                  {program.App_Show_Calendar__c === true ? (
                    <div>
                      <MenuItem component={Link} to={"/calendar"}>
                        <ListItemIcon>
                          <CalendarTodayIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Calendar</ListItemText>
                      </MenuItem>
                    </div>
                  ) : (
                    <div />
                  )}
                  {program.App_Show_Training_Program__c === true ? (
                    <div>
                      <MenuItem component={Link} to={"/training-programs"}>
                        <ListItemIcon>
                          <SportsSoccerIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Training programs</ListItemText>
                      </MenuItem>
                      <Divider />
                    </div>
                  ) : (
                    <div />
                  )}
                  {userTeam === "0019N000005n02vQAA" ? (
                    <div>
                      <MenuItem component={Link} to={"/orgportal"}>
                        <ListItemIcon>
                          <SportsSoccerIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manager Portal</ListItemText>
                      </MenuItem>
                      <Divider />

                    </div>
                  ) : (
                    <div />
                  )}
                  <MenuItem component={Link} to={"/user?" + values.Id}>
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <MenuItem component={Link} to={"/team"}>
                    <ListItemIcon>
                      <GroupIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Team Profile</ListItemText>
                  </MenuItem>
{/*                   <MenuItem component={Link} to={"/roster"}>
                    <ListItemIcon>
                      <GroupIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Team Roster</ListItemText>
                  </MenuItem>
 */}                  <MenuItem component={Link} to={"/gate?context=switchProgram"}>
                    <ListItemIcon>
                      <GroupIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Switch Program</ListItemText>
                  </MenuItem>
                </MenuList>
                <Card sx={{ border: "none", boxShadow: "none" }}>
                  <CardHeader
                    align="left"
                    titleTypographyProps={{ variant: "h6" }}
                    title={teamvalue.Name || "Team"}
                    sx={{ pb: 0 }}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <div>
                      <Table size="small" aria-label="a dense table">
                        <TableBody>
                          {members.map((row) => (
                            <TableRow
                              key={row.Id}
                              sx={{ "& td": { border: 0 } }}
                            >
                              <TableCell sx={{ pl: 0 }}>
                                <Avatar
                                  name={row.name}
                                  size="38"
                                  src={
                                    "https://agxpxlknzr.cloudimg.io/" +
                                    row.avatar__c +
                                    "?width=38&height=38&func=face"
                                  }
                                />
                              </TableCell>
                              <TableCell sx={{ pl: 0 }}>
                                <Typography
                                  align="left"
                                  variant="body1"
                                  noWrap
                                  sx={{ pb: 0 }}
                                >
                                  <MuiLink
                                    component="button"
                                    onClick={() => viewPlayer(row.id)}
                                    variant="body1"
                                    underline="hover"
                                  >
                                    {row.name}
                                  </MuiLink>
                                </Typography>
                                <Typography align="left" variant="caption">
                                  {row.userprofile__c}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </CardContent>
                  {/*                   <CardActions disableSpacing>
                    <Button
                      component={Link}
                      to={"/team?id=" + values.npsp__Primary_Affiliation__c}
                      size="small"
                    >
                      View Team
                    </Button>
                  </CardActions>
 */}{" "}
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              {events.length ? (
                <div>
                  <Box
                    component="div"
                    sx={{ p: 0, width: 1, border: "0px dashed grey" }}
                  >
                    <Typography align="left" sx={{ m: 1, pb: 0 }} variant="h5">
                      Invitations
                    </Typography>
                    <TableContainer>
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>RSVP</TableCell>

                            <TableCell>Event</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {events.map((row) => (
                            <TableRow
                              key={row.Id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <NiceSelectFormControl size="small">
                                  <Select
                                    labelId="invite-select-label"
                                    id="eventRSVP"
                                    name="RSVP"
                                    onChange={handleRSVP(row)}
                                    value={row.RSVP__c}
                                  >
                                    <MenuItem
                                      disabled
                                      key="Awaiting"
                                      value="Awaiting"
                                    >
                                      Awaiting
                                    </MenuItem>
                                    <MenuItem key="Yes" value="Yes">
                                      Yes
                                    </MenuItem>
                                    <MenuItem key="No" value="No">
                                      No
                                    </MenuItem>
                                  </Select>
                                </NiceSelectFormControl>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  noWrap
                                  sx={{ pb: 0 }}
                                  align="left"
                                  variant="body1"
                                >
                                  {row.Training_Event_Name__c}
                                </Typography>
                                <Typography
                                  sx={{ pb: 0 }}
                                  align="left"
                                  variant="body2"
                                >
                                  {row.Training_Event_Type__c}
                                </Typography>
                                <Typography align="left" variant="caption">
                                  {format(
                                    parseJSON(row.Date_and_Time__c),
                                    "EEEE do MMM hh:mm"
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              ) : (
                <div />
              )}
              {programEvents.length && userProgram === "0019N000005Go40QAC" ? (
                <div>
                  <Box
                    component="div"
                    sx={{ p: 0, width: 1, border: "0px dashed grey" }}
                  >
                    <Typography
                      align="left"
                      sx={{ mt: 1, ml: 1, pb: 0 }}
                      variant="h5"
                    >
                      Calendar
                    </Typography>
                    <Card sx={{ border: "none", boxShadow: "none" }}>
                      {programEvents.map((row) => (
                        <CardContent>
                          <Typography
                            align="left"
                            sx={{ p: 0 }}
                            variant="body1"
                          >
                            {row.event_day}
                          </Typography>
                          <Typography
                            align="left"
                            sx={{ p: 0 }}
                            variant="body2"
                          >
                            {row.event_localtime}
                          </Typography>
                          <Typography
                            align="left"
                            sx={{ p: 0 }}
                            variant="body2"
                          >
                            {row.event_city}, {row.event_location}
                          </Typography>
                        </CardContent>
                      ))}
                    </Card>
                  </Box>
                </div>
              ) : (
                <div />
              )}
              {showRanking === true ? (
                <div>
                  <Typography
                    align="left"
                    sx={{ mt: 0, ml: 1, pb: 2 }}
                    variant="h5"
                  >
                    Ranking
                  </Typography>
                  <TableContainer>
                    <Table
                      sx={{ overflow: "hidden" }}
                      size="medium"
                      aria-label="a dense table"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />

                      <TableBody>
                        {stableSort(
                          programRankings,
                          getComparator(order, orderBy)
                        ).map((row) => {
                          return (
                            <TableRow
                              key={row.team_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                sx={{ m: 0, p: 0, pt: 0, pb: 1 }}
                                component="th"
                                scope="row"
                              >
                                {row.team_name}
                              </TableCell>
                              <TableCell
                                sx={{ m: 0, p: 0, pb: 1 }}
                                align="right"
                              >
                                {row.action_p}
                              </TableCell>
                              <TableCell
                                sx={{ m: 0, p: 0, pb: 1 }}
                                align="right"
                              >
                                {row.style_p}
                              </TableCell>
                              <TableCell
                                sx={{ m: 0, p: 0, pb: 1 }}
                                align="right"
                              >
                                {row.spirit_p}
                              </TableCell>
                              <TableCell
                                sx={{ m: 0, p: 0, pb: 1 }}
                                align="right"
                              >
                                {row.game_p}
                              </TableCell>
                              <TableCell
                                sx={{ m: 0, p: 0, pb: 1 }}
                                align="right"
                              >
                                {row.crowd_p}
                              </TableCell>
                              <TableCell
                                sx={{ m: 0, p: 0, pb: 1 }}
                                align="right"
                              >
                                {row.total_p}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div />
              )}
              {todosAR.length ? (
                <div>
                  <Box
                    component="div"
                    sx={{ p: 0, width: 1, border: "0px dashed grey" }}
                  >
                    <Typography align="left" sx={{ m: 1, pb: 0 }} variant="h5">
                      Action Reports
                    </Typography>
                    <TableContainer>
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Title</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {todosAR.map((row) => (
                            <TableRow
                              key={row.Id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  align="left"
                                  variant="body1"
                                  sx={{ pb: 0 }}
                                >
                                  <MuiLink
                                    component="button"
                                    onClick={() =>
                                      doTodoAR(row.Id, row.EIR_Action_Report__c)
                                    }
                                    variant="body1"
                                    underline="hover"
                                  >
                                    {row.Title__c}
                                  </MuiLink>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              ) : (
                <div />
              )}
              {showTeamProfile ? (
                <div>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title="Team Intro" />
                    <CardContent>
                      <Typography align="left" variant="body2">
                        {teamvalue.Introduce_the_team_club__c ||
                          "The team introduction comes here..."}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title="Team Global Goal" />
                    <NiceCardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={flipGG(teamvalue.Global_Goal__c)}
                      alt="GGWCUP Ball"
                    />
                  </Card>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader
                      align="left"
                      title="Why is this Goal important to the Team"
                    />
                    <CardContent>
                      <Typography align="left" variant="body2">
                        {teamvalue.Global_Goal_Why__c ||
                          "Why is this Goal important to the Team"}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader
                      align="left"
                      title="How will the Team take action for the Goal"
                    />
                    <CardContent>
                      <Typography align="left" variant="body2">
                        {teamvalue.Global_Goal_Action__c ||
                          "How will the Team take action for the Goal"}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader
                      align="left"
                      title="How will the Team measure impact and reach?"
                    />
                    <CardContent>
                      <Typography align="left" variant="body2">
                        {teamvalue.Global_Goal_Impact__c ||
                          "How will the Team measure impact and reach?"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              ) : (
                <div />
              )}
              {showActionData ? (
                <div>
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title="Global Program Activities" />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                Hours trained
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q4_hours}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                Observers/Attendance
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q7_observers}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                Community actions
                              </Typography>
                              <Typography variant="h4">
                                {programAR_Q14_actions}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                People reached
                              </Typography>

                              <Typography variant="h4">
                                {programAR_Q18_people}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <br />
                  <Card sx={{ border: "none", boxShadow: "none" }}>
                    <CardHeader align="left" title="Team  Activities" />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                Hours trained
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q4_hours}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                Observers/Attendance
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q7_observers}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                Community actions
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q14_actions}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={6}>
                          <Card sx={{}}>
                            <CardContent sx={{ p: 1 }}>
                              <Typography sx={{ pb: 1 }} variant="body2">
                                People reached
                              </Typography>
                              <Typography variant="h4">
                                {teamAR_Q18_people}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              ) : (
                <div />
              )}
              {/* Show team members if on mobile */}
              <Card
                sx={{
                  border: "none",
                  boxShadow: "none",
                  pt: 1,
                  display: { xs: "block", md: "none" },
                }}
              >
                <CardHeader
                  align="left"
                  sx={{ pb: 0 }}
                  title={teamvalue.Name || "Group"}
                />
                <CardContent sx={{ pt: 0 }}>
                  <div>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        {members.map((row) => (
                          <TableRow key={row.Id} sx={{ "& td": { border: 0 } }}>
                            <TableCell style={{ width: 50 }}>
                              <Avatar
                                name={row.name}
                                size="38"
                                src={
                                  "https://agxpxlknzr.cloudimg.io/" +
                                  row.avatar__c +
                                  "?width=38&height=38&func=face"
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ pl: 0 }}>
                              <Typography
                                align="left"
                                variant="body1"
                                noWrap
                                sx={{ pb: 0 }}
                              >
                                <MuiLink
                                  component="button"
                                  onClick={() => viewPlayer(row.id)}
                                  variant="body1"
                                  underline="hover"
                                >
                                  {row.name}
                                </MuiLink>
                              </Typography>
                              <Typography align="left" variant="caption">
                                {row.userprofile__c}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </CardContent>
              </Card>
              {/* Show team GG if on mobile */}
              <Card
                sx={{
                  border: "none",
                  boxShadow: "none",
                  pt: 2,
                  display: { xs: "flex", md: "none" },
                }}
              >
                <Timeline
                  dataSource={{ sourceType: "profile", screenName: "ggwcup" }}
                  options={{ height: "600" }}
                />
              </Card>
            </Grid>
            <Grid item xs={3} sx={{ display: { xs: "none", md: "flex" } }}>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Card sx={{ border: "none", boxShadow: "none" }}>
                  <CardHeader
                    title={values.UserProfile__c || "Your Role"}
                    subheader={teamvalue.Name || "Your team"}
                  />
                  <Avatar
                    name={values.Name}
                    size="150"
                    src={
                      "https://agxpxlknzr.cloudimg.io/" +
                      values.Avatar__c +
                      "?width=150&height=150"
                    }
                  />
                </Card>
                <Card sx={{ border: "none", boxShadow: "none" }}>
                  <Timeline
                    dataSource={{ sourceType: "profile", screenName: "ggwcup" }}
                    options={{ height: "600" }}
                  />
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </NiceBox>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Home;
