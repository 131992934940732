import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Container, Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "react-avatar";
import { UniqueArray } from "@lib";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import CardMedia from "@mui/material/CardMedia";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { format, parseJSON } from "date-fns";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//test email = walaashordom@yahoo.com

const EmailButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const NiceCheckboxFormControl = styled(FormControlLabel)({
  margin: "10px",
});

const Schedulescolumns = [
  { field: "id", headerName: "ID", width: 0 },
  { field: "game_id", headerName: "Event ID", width: 0 },
  { field: "team_id", headerName: "Home ID", width: 0 },
  { field: "opponent_id", headerName: "Away ID", width: 0 },
  {
    field: "game_day_name",
    headerName: "Game Day",
    width: 100,
    editable: false,
    hideable: false,
  },
  {
    field: "game_seq",
    headerName: "Seq",
    type: "number",
    width: 75,
    editable: false,
  },
  {
    field: "team_name",
    headerName: "Home team",
    width: 150,
    editable: false,
  },
  {
    field: "opponent_name",
    headerName: "Away team",
    width: 110,
    editable: false,
  },
  {
    field: "game_state",
    headerName: "Status",
    width: 75,
    editable: false,
  },
  {
    field: "fullName",
    headerName: "Game",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 350,
    valueGetter: (params) =>
      `${params.row.team_name || ""} vs ${params.row.opponent_name || ""}`,
  },
];

const ARTodoscolumns = [
  { field: "id", headerName: "ID", width: 0 },
  { field: "contact_id", headerName: "Contact ID", width: 0 },
  {
    field: "artodo_name",
    headerName: "Title",
    width: 200,
    editable: false,
    hideable: false,
  },
  {
    field: "team_name",
    headerName: "Team",
    width: 150,
    editable: false,
  },
  {
    field: "contact_name",
    headerName: "Captain",
    width: 150,
    editable: false,
  },
  {
    field: "artodo_state",
    headerName: "Status",
    width: 75,
    editable: false,
  },
  {
    field: "artodo_startdate",
    headerName: "Issued",
    width: 150,
    editable: false,
    renderCell: (params) => format(parseJSON(params.value), "EEEE do MMM"),
  },
  {
    field: "artodo_submitdate",
    headerName: "Submitted",
    width: 150,
    editable: false,
    renderCell: (params) => {
      if (params.value !== null) {
        return format(parseJSON(params.value), "EEEE do MMM");
        //return params.value;
      }
      return "Not submitted yet";
    },
  },
];

const Scorescolumns = [
  { field: "id", headerName: "ID", width: 0 },
  {
    field: "game_day_name",
    headerName: "Game Day",
    width: 100,
    editable: false,
    hideable: false,
  },
  {
    field: "team_name",
    headerName: "Team",
    width: 150,
    editable: false,
    hideable: false,
  },
  {
    field: "opponent_name",
    headerName: "Opponent",
    width: 150,
    editable: false,
  },
  {
    field: "score_type",
    headerName: "Game type",
    width: 150,
    editable: false,
  },
  {
    field: "score_state",
    headerName: "Status",
    width: 75,
    editable: false,
  },
  {
    field: "score_action_p",
    headerName: "Action",
    width: 75,
    editable: false,
  },
  {
    field: "score_style_p",
    headerName: "Purpose",
    width: 75,
    editable: false,
  },
  {
    field: "score_spirit_p",
    headerName: "Spirit",
    width: 75,
    editable: false,
  },
  {
    field: "score_game_p",
    headerName: "Game",
    width: 75,
    editable: false,
  },
  {
    field: "score_crowd_p",
    headerName: "Crowd",
    width: 75,
    editable: false,
  },

  {
    field: "score_total_p",
    headerName: "Total",
    width: 150,
    editable: false,
  },
];

const Teamscolumns = [
  { field: "id", headerName: "ID", width: 0 },
  {
    field: "team_photo",
    headerName: "Image",
    width: 120,
    editable: false,
    hideable: false,
    renderCell: (params) => (
      <img
        src={
          "https://agxpxlknzr.cloudimg.io/" +
          params.value +
          "?width=100&height=100"
        }
      />
    ),
  },
  {
    field: "team_name",
    headerName: "Team",
    width: 150,
    editable: false,
    hideable: false,
  },
  {
    field: "team_captain",
    headerName: "Captain",
    width: 200,
    editable: false,
  },
  {
    field: "team_captain_email",
    headerName: "Email",
    width: 250,
    editable: false,
  },
  {
    field: "team_profile_status",
    headerName: "Profile Status",
    width: 100,
    editable: false,
  },
];

const Eventscolumns = [
  { field: "id", headerName: "ID", width: 0 },
  {
    field: "event_day",
    headerName: "Event",
    width: 150,
    editable: false,
    hideable: false,
  },
  {
    field: "event_group",
    headerName: "Group",
    width: 50,
    editable: false,
  },
  {
    field: "event_time",
    headerName: "Date",
    width: 200,
    editable: false,
    //renderCell: (params) => format(parseJSON(params.value), "EEEE do MMM"),
  },
  {
    field: "event_state",
    headerName: "State",
    width: 100,
    editable: false,
  },
  {
    field: "event_voting",
    headerName: "Voting Open",
    width: 100,
    editable: false,
  },
  {
    field: "event_location",
    headerName: "Location",
    width: 300,
    editable: false,
  },
];

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

function OrgPortal() {
  let navigate = useNavigate();
  const confirm = useConfirm();

  const [openNewGame, setOpenNewGame] = useState(false);
  const [openShowResponse, setOpenShowResponse] = useState(false);
  const [message, setMessage] = useState("");
  const { t, i18n } = useTranslation();
  const theProgramID = sessionStorage.getItem("userProgram");
  const theUserID = sessionStorage.getItem("userId");
  const [checkedOpenEventList, setCheckedOpenEventList] = useState(false);
  const [openScoreCard, setOpenScoreCard] = useState(false);
  const [openTeamProfile, setOpenTeamProfile] = useState(false);
  const [scoreCardValues, setScoreCardValues] = useState([]);
  const [programEvents, setProgramEvents] = useState([]);
  const [activeProgramEvents, setActiveProgramEvents] = useState([]);
  const [programEventScorecards, setProgramEventScorecards] = useState([]);
  const [rawProgramEventScorecards, setRawProgramEventScorecards] = useState(
    []
  );
  const [programActionReportResponses, setProgramActionReportResponses] =
    useState([]);
  const [programARTodos, setProgramARTodos] = useState([]);
  const [rawProgramARTodos, setRawProgramARTodos] = useState([]);
  const [voteRankings, setVoteRankings] = useState([]);
  const [programGames, setProgramGames] = useState([]);
  const [rawProgramGames, setRawProgramGames] = useState([]);
  const [programEventsOpen, setProgramEventsOpen] = useState([]);
  const [programTeams, setProgramTeams] = useState([]);
  const [teamValue, setTeamValue] = useState([]);
  const [programTeamsHome, setProgramTeamsHome] = useState([]);
  const [programTeamsAway, setProgramTeamsAway] = useState([]);
  const [valuesNewGame, setValuesNewGame] = useState([]);
  const [valuesGameEditMode, setValuesGameEditMode] = useState(false);
  const [valuesActiveGridGame, setValuesActiveGridGame] = useState(0);
  const [valuesActiveGridGameEvent, setValuesActiveGridGameEvent] = useState(0);
  const [valuesActiveGridGameSeq, setValuesActiveGridGameSeq] = useState(0);
  const [valuesActiveGridGameId, setValuesActiveGridGameId] = useState(0);
  const [valuesActiveARId, setValuesActiveARId] = useState(0);
  const [valuesActiveARCaptainId, setValuesActiveARCaptainId] = useState(0);
  const [valuesActiveGridGameHome, setValuesActiveGridGameHome] = useState(0);
  const [valuesActiveGridGameAway, setValuesActiveGridGameAway] = useState(0);
  const [valuesNewGameHomeDisabled, setValuesNewGameHomeDisabled] =
    useState(true);
  const [valuesNewGameAwayDisabled, setValuesNewGameAwayDisabled] =
    useState(true);
  const [valuesNewGameSeqDisabled, setValuesNewGameSeqDisabled] =
    useState(true);
  const [valuesNewGameSaveDisabled, setValuesNewGameSaveDisabled] =
    useState(true);
  const [valuesGameDeleteDisabled, setValuesGameDeleteDisabled] =
    useState(true);
  const [valuesOpenARTODODisabled, setValuesOpenARTODODisabled] =
    useState(true);
  const [valuesCloseARTODODisabled, setValuesCloseARTODODisabled] =
    useState(true);
  const [valuesRemindARTODODisabled, setValuesRemindARTODODisabled] =
    useState(true);
  const [valuesViewARTODODisabled, setValuesViewARTODODisabled] =
    useState(true);
  const [valuesActiveEventId, setValuesActiveEventId] = useState(0);
  const [valuesViewEventCloseDisabled, setValuesViewEventCloseDisabled] =
    useState(true);
  const [valuesViewEventOpenDisabled, setValuesViewEventOpenDisabled] =
    useState(true);
  const [valuesViewEventVoteOpenDisabled, setValuesViewEventVoteOpenDisabled] =
    useState(true);
  const [valuesViewEventVoteCloseDisabled, setValuesViewEventVoteCloseDisabled] =
    useState(true);

  const [valuesActiveTeamId, setValuesActiveTeamId] = useState(0);
  const [valuesActiveScoreId, setValuesActiveScoreId] = useState(0);
  const [valuesEditScoreDisabled, setValuesEditScoreDisabled] = useState(true);
  const [tab, setTab] = useState("events");
  const [tabPreview, setTabPreview] = useState("events");

  function viewActionReportresponses() {
    FetchActionReportResponses(valuesActiveARId);
    setOpenShowResponse(true);
  }

  function viewEventDetails() { }

  function editScorecard() {
    setScoreCardValues(valuesActiveScoreId);
    setOpenScoreCard(true);
  }

  function closeLyketVoting() {
    confirm({
      title:
        "Please confirm that you want to close voting for Purpose Points",
      description:
        "This will disable the voting buttons in the public app",
    })
      .then(() => {
        FetchVoteRank()
      })
      .catch(() => {
        /* ... */
      });
  }

  function openLyketVoting() {
    confirm({
      title:
        "Please confirm that you want to open voting for Purpose Points",
      description:
        "This will enable the voting buttons in the public app",
    })
      .then(() => {
        openVoting()
      })
      .catch(() => {
        /* ... */
      });
  }


  const FetchVoteRank = async () => {
    const lyketSession = localStorage.getItem("lyket-session-id");
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer pt_84b4c080ef6b0e33e91b1d49d9b02b',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Session-Id': lyketSession
      }
    };
    const theURL =
      "https://api.lyket.dev/v1/rank/like-buttons/ILD24";
    const response = await fetch(theURL, requestOptions);
    const votedata = await response.json();
    try {
      //console.log("votedata=",votedata.date);
      closeVoting(votedata.data);
    } catch (err) {
      console.log(err);
    }
  };

  const closeVoting = async (lyketData) => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/closeVoting";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = lyketData;
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response;
    try {
      FetchProgramEvents(theProgramID);
      FetchScorecardGames(theProgramID);
      setValuesViewEventVoteOpenDisabled(true);
      setValuesViewEventVoteCloseDisabled(true);
    } catch (err) {
      console.log(err);
    }
  };

  const openVoting = async () => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/openVoting";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {};
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response;
    try {
      FetchProgramEvents(theProgramID);
      setValuesViewEventVoteOpenDisabled(true);
      setValuesViewEventVoteCloseDisabled(true);
    } catch (err) {
      console.log(err);
    }
  };

  function closeEvent() {
    confirm({
      title: "Please confirm that you want to close this Game Day event",
    })
      .then(() => { })
      .catch(() => {
        /* ... */
      });
  }

  function openEvent() {
    confirm({
      title: "Please confirm that you want to open this Game Day event",
      description:
        "This will close the previous open Game Day, issue Action Reports to captains, lock Game schedules and prepare scorecards for the Game Schedules - THIS CAN NOT BE UNDONE",
    })
      .then(() => { })
      .catch(() => {
        /* ... */
      });
  }

  function closeAR() {
    confirm({
      title: "Please confirm that you want to close this Action Report",
      description:
        "It will prevent the captain from filling out the Action report and earn points for the ranking",
    })
      .then(() => {
        flipARstate("Closed");
        setValuesCloseARTODODisabled(true);
        setValuesRemindARTODODisabled(true);
        setValuesOpenARTODODisabled(false);
      })
      .catch(() => {
        /* ... */
      });
  }

  function openAR() {
    confirm({
      title: "Please confirm that you want to re-open this Action Report",
      description:
        "It will allow the captain from filling out the Action report and earn points for the ranking",
    })
      .then(() => {
        flipARstate("Open");
        setValuesCloseARTODODisabled(false);
        setValuesRemindARTODODisabled(false);
        setValuesOpenARTODODisabled(true);
      })
      .catch(() => {
        /* ... */
      });
  }

  function remindAR() {
    confirm({
      title:
        "Please confirm that you want to send a reminder email for this Action Report",
      description:
        "It will send an email to the captain about filling out the Action report and earn points for the ranking",
    })
      .then(() => {
        //console.log(valuesActiveARCaptainId);
        sendARReminder(valuesActiveARCaptainId);
      })
      .catch(() => {
        /* ... */
      });
  }

  function viewTeamProfile() {
    FetchTeam(valuesActiveTeamId);
    setOpenTeamProfile(true);
  }

  function viewCaptainProfile() { }

  function viewNewGame() {
    setValuesGameEditMode(false);
    setValuesNewGame({
      ...valuesNewGame,
      event_id: "",
      home_team_id: "",
      away_team_id: "",
      game_seq: "",
    });
    setValuesNewGameHomeDisabled(true);
    setValuesNewGameAwayDisabled(true);
    setValuesNewGameSeqDisabled(true);

    setOpenNewGame(true);
  }

  function viewEditGame() {
    setValuesGameEditMode(true);
    setValuesNewGame({
      ...valuesNewGame,
      event_id: valuesActiveGridGameEvent,
      home_team_id: valuesActiveGridGameHome,
      away_team_id: valuesActiveGridGameAway,
      game_seq: valuesActiveGridGameSeq,
      game_id: valuesActiveGridGameSeq,
    });
    setValuesNewGameHomeDisabled(false);
    setValuesNewGameAwayDisabled(false);
    setValuesNewGameSeqDisabled(false);

    setOpenNewGame(true);
  }

  function viewDeleteGame() {
    confirm({
      title: "Please confirm that you want to delete this game",
    })
      .then(() => {
        DeleteGame(valuesActiveGridGame);
      })
      .catch(() => {
        /* ... */
      });
  }

  const toggleNewGame = (newOpen) => () => {
    setOpenNewGame(newOpen);
  };

  const toggleShowResponse = (newOpen) => () => {
    setOpenShowResponse(newOpen);
  };

  function scanListHome(theEventID) {
    console.log("param=", theEventID);
    console.log("data=", programTeams);
    var theEvent = programEvents.find(
      (item) => item["event_id"] === theEventID
    );
    var scanData = programTeams;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.program_group === theEvent.event_group) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setProgramTeamsHome(scanData);
  }

  function scanListAway(theHome) {
    console.log("data=", theHome);
    var scanData = programTeamsHome;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.team_id !== theHome) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setProgramTeamsAway(scanData);
  }

  function scanListEvents(theState, theData) {
    //console.log("data=", programEvents);
    var scanData = theData;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.event_state === theState) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setProgramEventsOpen(scanData);
  }

  function findOpenEvent(theData) {
    var scanData = theData;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.event_state === "open") {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    //console.log("data=", scanData[0]);
    setActiveProgramEvents(scanData[0]);
  }

  function limitGames() {
    var scanData = rawProgramGames;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.event_id === activeProgramEvents.event_id) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setProgramGames(scanData);
  }

  function limitAR() {
    var scanData = rawProgramARTodos;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.event_id === activeProgramEvents.event_id) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setProgramARTodos(scanData);
  }

  function limitScorecards() {
    var scanData = rawProgramEventScorecards;
    scanData = scanData.reduce((filterItems, item) => {
      if (item.event_id === activeProgramEvents.event_id) {
        filterItems.push(item);
      }
      return filterItems;
    }, []);
    setProgramEventScorecards(scanData);
  }

  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function lookupGG(theId) {
    if (theId === "a0u090000010VD5AAM") {
      return "Global Goal 1 - No Poverty";
    }
    if (theId === "a0u090000010VDAAA2") {
      return "Global Goal 2 - Zero Hunger";
    }
    if (theId === "a0u090000010VyvAAE") {
      return "Global Goal 3 - Good Health and Well-being";
    }
    if (theId === "a0u090000010VyhAAE") {
      return "Global Goal 4 - Quality Education";
    }
    if (theId === "a0u090000010Vz1AAE") {
      return "Global Goal 5 - Gender Equality";
    }
    if (theId === "a0u090000010VyiAAE") {
      return "Global Goal 6 - Clean Water and Sanitation";
    }
    if (theId === "a0u090000010Vz5AAE") {
      return "Global Goal 7 - Affordable and Clean Energy";
    }
    if (theId === "a0u090000010VzAAAU") {
      return "Global Goal 8 - Decent Work and Economic Growth";
    }
    if (theId === "a0u090000010Vz6AAE") {
      return "Global Goal 9 - Industry, Innovation and Infrastructure";
    }
    if (theId === "a0u090000010VzKAAU") {
      return "Global Goal 10 - Reduced Inequalities";
    }
    if (theId === "a0u090000010VzLAAU") {
      return "Global Goal 11 - Sustainable Cities and Communities";
    }
    if (theId === "a0u090000010VzBAAU") {
      return "Global Goal 12 - Responsible Consumption and Production";
    }
    if (theId === "a0u090000010VzPAAU") {
      return "Global Goal 13 - Climate Action";
    }
    if (theId === "a0u090000010VymAAE") {
      return "Global Goal 14 - Life Below Water";
    }
    if (theId === "a0u090000010VzUAAU") {
      return "Global Goal 15 - Life On Land";
    }
    if (theId === "a0u090000010VzZAAU") {
      return "Global Goal 16 - Peace, Justice and Strong Institutions";
    }
    if (theId === "a0u090000010VywAAE") {
      return "Global Goal 17 - Partnership For The Goals";
    }
    return null;
  }

  const handleInputChangeNewGame = (e) => {
    //console.log("e.target=", e.target);
    const { name, value } = e.target;
    if (name === "event_id") {
      setValuesNewGame({
        ...valuesNewGame,
        event_id: value,
        home_team_id: "",
        away_team_id: "",
        game_seq: "",
      });
      scanListHome(value);
      setValuesNewGameHomeDisabled(false);
      setValuesNewGameAwayDisabled(true);
      setValuesNewGameSeqDisabled(true);
      setValuesNewGameSaveDisabled(true);
    }
    if (name === "home_team_id") {
      setValuesNewGame({
        ...valuesNewGame,
        home_team_id: value,
        away_team_id: "",
        game_seq: "",
      });
      scanListAway(value);
      setValuesNewGameAwayDisabled(false);
      setValuesNewGameSeqDisabled(true);
      setValuesNewGameSaveDisabled(true);
    }
    if (name === "away_team_id") {
      setValuesNewGame({
        ...valuesNewGame,
        away_team_id: value,
        game_seq: "",
      });
      setValuesNewGameSeqDisabled(false);
      setValuesNewGameSaveDisabled(true);
    }
    if (name === "game_seq") {
      setValuesNewGame({
        ...valuesNewGame,
        game_seq: value,
      });
      setValuesNewGameSaveDisabled(false);
    }
  };

  useEffect(() => {
    i18n.changeLanguage("en");
    FetchProgramEvents(theProgramID);
    FetchProgramGames(theProgramID);
    FetchProgramTeams(theProgramID);
    FetchProgramARTodos(theProgramID);
    FetchScorecardGames(theProgramID);
    setValuesGameDeleteDisabled(true);
    setValuesGameEditMode(false);
  }, []);

  const FetchProgramGames = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/leagueGames/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    //console.log("FetchProgramGames=", data);
    try {
      setProgramGames(data);
      setRawProgramGames(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramTeams = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/programTeams/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setProgramTeams(data);
      setProgramTeamsHome(data);
      setProgramTeamsAway(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramARTodos = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/actionReportTodos/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    //console.log("FetchProgramARs=",data);
    try {
      setProgramARTodos(data);
      setRawProgramARTodos(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchProgramEvents = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/leagueEvents/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    //console.log(data);
    try {
      setProgramEvents(data);
      findOpenEvent(data);
      scanListEvents("pending", data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchScorecardGames = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/allScorecards/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      //console.log("FetchProgramScoreCards",data);
      setProgramEventScorecards(data);
      setRawProgramEventScorecards(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchActionReportResponses = async (Id) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/actionReportResponse/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      //console.log(data);
      setProgramActionReportResponses(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchTeam = async (Id) => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/appTeam/" + Id;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      //console.log(data[0]);
      setTeamValue(data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleTabChangePreview = (event, newValue) => {
    setTabPreview(newValue);
  };

  const handleScheduleRowClick = (params) => {
    setValuesActiveGridGame(params.row.id);
    setValuesActiveGridGameEvent(params.row.game_id);
    setValuesActiveGridGameSeq(params.row.game_seq);
    setValuesActiveGridGameHome(params.row.team_id);
    setValuesActiveGridGameAway(params.row.opponent_id);
    setValuesActiveGridGameId(params.row.game_id);
    if (params.row.game_state === "pending") {
      setValuesGameDeleteDisabled(false);
    } else {
      setValuesGameDeleteDisabled(true);
    }
    //setMessage(`Game ${params.row.team_name} vs ${params.row.opponent_name} with Status =  ${params.row.game_state} and ${params.row.game_id} as event_id was clicked`);
  };

  const handleARRowClick = (params) => {
    setValuesActiveARId(params.row.id);
    if (params.row.artodo_state === "Submitted") {
      setValuesViewARTODODisabled(false);
    } else {
      setValuesViewARTODODisabled(true);
    }
    if (params.row.artodo_state === "Open") {
      setValuesActiveARCaptainId(params.row.contact_id);
      setValuesCloseARTODODisabled(false);
      setValuesRemindARTODODisabled(false);
    } else {
      setValuesCloseARTODODisabled(true);
      setValuesRemindARTODODisabled(true);
    }
    if (params.row.artodo_state === "Closed") {
      setValuesOpenARTODODisabled(false);
    } else {
      setValuesOpenARTODODisabled(true);
    }

    //setMessage(`Status =  ${params.row.artodo_state} and ${params.row.id} as ARTODO was clicked`);
  };

  const handleCheckedOpenEventList = (event, newValue) => {
    setCheckedOpenEventList(newValue);
    //console.log(newValue);
    if (newValue) {
      limitGames();
      limitAR();
      limitScorecards();
    } else {
      setProgramGames(rawProgramGames);
      setProgramARTodos(rawProgramARTodos);
      setProgramEventScorecards(rawProgramEventScorecards);
    }
  };

  const handleEventRowClick = (params) => {
    setValuesActiveEventId(params.row.id);
    if (params.row.event_state === "pending") {
      setValuesViewEventOpenDisabled(false);
    } else {
      setValuesViewEventOpenDisabled(true);
    }
    if (params.row.event_state === "open") {
      if (params.row.event_voting === false) {
        setValuesViewEventVoteOpenDisabled(false);
        setValuesViewEventVoteCloseDisabled(true);
      } else {
        setValuesViewEventVoteOpenDisabled(true);
        setValuesViewEventVoteCloseDisabled(false);
      }
    }

    //setMessage(`Status =  ${params.row.artodo_state} and ${params.row.id} as ARTODO was clicked`);
  };

  const handleTeamRowClick = (params) => {
    setValuesActiveTeamId(params.row.team_id);
    //setMessage(`Status =  ${params.row.team_name} and ${params.row.team_id} as TEAM was clicked`);
  };

  const handleScoreRowClick = (params) => {
    setValuesActiveScoreId(params.row);
    setValuesEditScoreDisabled(false);
    //setMessage(`Status =  ${params.row.artodo_state} and ${params.row.id} as ARTODO was clicked`);
  };

  function closeScoreCard() {
    setOpenScoreCard(false);
  }

  const toggleOpenScoreCard = (newOpen) => () => {
    setOpenScoreCard(newOpen);
  };

  const toggleOpenTeamDrawer = (newOpen) => () => {
    setOpenTeamProfile(newOpen);
  };

  const handleScorecardInputChange = (e) => {
    console.log("e.target=", e.target);
    //console.log("e.target.checked=", e.target.checked);
    const { name, value } = e.target;
    setScoreCardValues({
      ...scoreCardValues,
      [name]: value,
    });
  };

  const SaveScoreCard = async (state) => {
    var theCount = 0;
    if (state === "Closed") {
      theCount = 1;
    }
    console.log("before save= ", scoreCardValues);
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/updateScoreCard";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      score_id: scoreCardValues.score_id,
      score_ar_id: scoreCardValues.score_ar_id,
      score_count: theCount,
      score_state: state,
      score_action_p: scoreCardValues.score_action_p,
      score_style_p: scoreCardValues.score_style_p,
      score_spirit_p: scoreCardValues.score_spirit_p,
      score_crowd_p: scoreCardValues.score_crowd_p,
      score_game_p: scoreCardValues.score_game_p,
      reporter: theUserID,
    };
    console.log(theBody);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response.json();
    try {
      FetchScorecardGames(theProgramID);
      setOpenScoreCard(false);
    } catch (err) {
      console.log(err);
    }
  };

  const SaveGame = async () => {
    if (valuesGameEditMode) {
      const theURL =
        "https://backend-app-9ofqo.ondigitalocean.app/updateGameSchedule";
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var theBody = {
        program: valuesNewGame.event_id,
        seq: valuesNewGame.game_seq,
        home: valuesNewGame.home_team_id,
        away: valuesNewGame.away_team_id,
        user: theUserID,
        game: valuesActiveGridGame,
      };
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(theBody, null, 2),
        redirect: "follow",
      };
      const response = await fetch(theURL, requestOptions);
      const data = await response.json();
      try {
        FetchProgramGames(theProgramID);
        setOpenNewGame(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      const theURL =
        "https://backend-app-9ofqo.ondigitalocean.app/newGameSchedule";
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var theBody = {
        program: valuesNewGame.event_id,
        type: "Game",
        element: "Football Game",
        seq: valuesNewGame.game_seq,
        home: valuesNewGame.home_team_id,
        away: valuesNewGame.away_team_id,
        reporter: theUserID,
      };
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(theBody, null, 2),
        redirect: "follow",
      };
      const response = await fetch(theURL, requestOptions);
      const data = await response.json();
      try {
        FetchProgramGames(theProgramID);
        setOpenNewGame(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const DeleteGame = async (theGameId) => {
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/deleteGameSchedule";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      game_id: theGameId,
      user_id: theUserID,
    };
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response;
    try {
      FetchProgramGames(theProgramID);
      setValuesGameDeleteDisabled(true);
    } catch (err) {
      console.log(err);
    }
  };

  const sendARReminder = async (theCaptainID) => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/sendARReminder";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      id: theCaptainID,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response;
    try {
    } catch (err) {
      console.log(err);
    }
  };

  const flipARstate = async (theState) => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/updateAR";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var theBody = {
      id: valuesActiveARId,
      state: theState,
    };
    //console.log(theBody);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(theBody, null, 2),
      redirect: "follow",
    };
    const response = await fetch(theURL, requestOptions);
    const data = await response;
    try {
      FetchProgramARTodos(theProgramID);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthenticatedLayout title="Directory">
      {/* Game Schedule*/}
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "30%",
              xl: "30%",
            },
          },
        }}
        anchor="right"
        open={openNewGame}
        onClose={toggleNewGame(false)}
        onOpen={toggleNewGame(true)}
      >
        <Container>
          {" "}
          <Box
            sx={{ pt: 2, pr: 2, pb: 5 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              New game
            </Typography>
            <CloseIcon
              fontSize="large"
              align="right"
              onClick={toggleNewGame(false)}
            />
          </Box>
          <form>
            <Grid align="left" container spacing={2}>
              <Grid item>
                <div>
                  <NiceSelectFormControl fullWidth>
                    <InputLabel id="game-day-label">Game Day *</InputLabel>
                    <Select
                      align="left"
                      required
                      labelId="game-day-label"
                      id="game-day"
                      label="Game Day"
                      name="event_id"
                      onChange={handleInputChangeNewGame}
                      value={valuesNewGame.event_id || ""}
                    >
                      {programEventsOpen.map((item) => (
                        <MenuItem key={item.event_id} value={item.event_id}>
                          {item.event_day}
                        </MenuItem>
                      ))}
                    </Select>
                  </NiceSelectFormControl>
                  <NiceSelectFormControl fullWidth>
                    <InputLabel id="game-home-label">Home team *</InputLabel>
                    <Select
                      align="left"
                      required
                      disabled={valuesNewGameHomeDisabled}
                      labelId="game-home-label"
                      id="game-home"
                      label="Home team"
                      name="home_team_id"
                      onChange={handleInputChangeNewGame}
                      value={valuesNewGame.home_team_id || ""}
                    >
                      {programTeamsHome.map((item) => (
                        <MenuItem key={item.team_id} value={item.team_id}>
                          {item.team_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </NiceSelectFormControl>
                  <NiceSelectFormControl fullWidth>
                    <InputLabel id="game-away-label">Away team *</InputLabel>
                    <Select
                      align="left"
                      required
                      labelId="game-away-label"
                      id="game-home"
                      label="Home team"
                      name="away_team_id"
                      disabled={valuesNewGameAwayDisabled}
                      onChange={handleInputChangeNewGame}
                      value={valuesNewGame.away_team_id || ""}
                    >
                      {programTeamsAway.map((item) => (
                        <MenuItem key={item.team_id} value={item.team_id}>
                          {item.team_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </NiceSelectFormControl>
                  <NiceSelectFormControl fullWidth>
                    <InputLabel id="game-seq-label">Sequence *</InputLabel>
                    <Select
                      align="left"
                      required
                      labelId="game-seq-label"
                      id="game-seq"
                      label="Sequence"
                      name="game_seq"
                      disabled={valuesNewGameSeqDisabled}
                      onChange={handleInputChangeNewGame}
                      value={valuesNewGame.game_seq || ""}
                    >
                      <MenuItem key="1" value="1">
                        1
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        2
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        3
                      </MenuItem>
                      <MenuItem key="4" value="4">
                        4
                      </MenuItem>
                      <MenuItem key="5" value="5">
                        5
                      </MenuItem>
                      <MenuItem key="6" value="6">
                        6
                      </MenuItem>
                      <MenuItem key="7" value="7">
                        7
                      </MenuItem>
                      <MenuItem key="8" value="8">
                        8
                      </MenuItem>
                      <MenuItem key="0" value="9">
                        9
                      </MenuItem>
                      <MenuItem key="10" value="10">
                        10
                      </MenuItem>
                    </Select>
                  </NiceSelectFormControl>
                </div>
                <Box sx={{ m: 1, pb: 0 }}>
                  <Button
                    disabled={valuesNewGameSaveDisabled}
                    onClick={() => SaveGame()}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Drawer>
      {/* AR Response */}
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "30%",
              xl: "30%",
            },
          },
        }}
        anchor="right"
        open={openShowResponse}
        onClose={toggleShowResponse(false)}
        onOpen={toggleShowResponse(true)}
      >
        <Container>
          {" "}
          <Box
            sx={{ pt: 2, pr: 2, pb: 5 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              Action Report Answers
            </Typography>
            <CloseIcon
              fontSize="large"
              align="right"
              onClick={toggleShowResponse(false)}
            />
          </Box>
          <form>
            <Grid align="left" container spacing={2}>
              <Grid item>
                {programActionReportResponses.map((row) => (
                  <TableRow
                    key={row.response_question}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>
                      <Typography align="left" variant="body1" sx={{ pb: 0 }}>
                        {row.league_text}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="right" variant="body1" sx={{ pb: 0 }}>
                        {row.response_display_value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </Grid>
            </Grid>
          </form>
        </Container>
      </Drawer>
      {/* Scorecard*/}
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "66%",
              xl: "66%",
            },
          },
        }}
        anchor="right"
        open={openScoreCard}
        onClose={toggleOpenScoreCard(false)}
        onOpen={toggleOpenScoreCard(true)}
      >
        <Container>
          {" "}
          <Box
            sx={{ pt: 2, pr: 2 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              SCORECARD
            </Typography>

            <CloseIcon
              fontSize="large"
              align="right"
              onClick={toggleOpenScoreCard(false)}
            />
          </Box>
          <Typography sx={{ pt: 2, pr: 2 }} align="left" variant="body1">
            The game was {scoreCardValues.team_name} vs{" "}
            {scoreCardValues.opponent_name}
          </Typography>
          <Typography sx={{ pb: 2, pr: 2 }} align="left" variant="h6">
            Score for {scoreCardValues.team_name}
          </Typography>
          {scoreCardValues.score_type === "Game" ? (
            <div>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="game_label">Footbal Points</InputLabel>
                <Select
                  labelId="game_label"
                  id="gamepoints"
                  value={scoreCardValues.score_game_p || "0"}
                  label="Football Points"
                  name="score_game_p"
                  onChange={handleScorecardInputChange}
                >
                  <MenuItem key="game0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="game1" value="1">
                    1 Point - Team lost
                  </MenuItem>
                  <MenuItem key="gane2" value="2">
                    2 Points - It was a tie
                  </MenuItem>
                  <MenuItem key="game3" value="3">
                    3 Points - Team won
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
            </div>
          ) : (
            <div>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="action_label">Action Points</InputLabel>
                <Select
                  labelId="action_label"
                  id="actionpoints"
                  value={scoreCardValues.score_action_p || "0"}
                  label="Action Points"
                  name="score_action_p"
                  onChange={handleScorecardInputChange}
                >
                  <MenuItem key="action0" value="0">
                    No points available from Action Report yet
                  </MenuItem>
                  <MenuItem key="action1" value="1">
                    1 Point - Team met up once
                  </MenuItem>
                  <MenuItem key="action3" value="3">
                    3 Points - Team met up once and engaged more than 10
                    external people
                  </MenuItem>
                  <MenuItem key="action5" value="5">
                    5 Points - Team met up once and super engaged over 40
                    external people
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              {/*               
              <NiceSelectFormControl fullWidth>
                <InputLabel id="style_label">Style Points</InputLabel>
                <Select
                  labelId="style_label"
                  id="stylepoints"
                  value={scoreCardValues.score_style_p || "0"}
                  label="Style Points"
                  name="score_style_p"
                  onChange={handleScorecardInputChange}
                >
                  <MenuItem key="style0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="style1" value="1">
                    1 Point - Team identity element iconic to the team
                  </MenuItem>
                  <MenuItem key="style2" value="2">
                    2 Point
                  </MenuItem>
                  <MenuItem key="style3" value="3">
                    3 Points - Team's style is sustainably conscious or
                    up-cycled
                  </MenuItem>
                  <MenuItem key="style4" value="4">
                    4 Point
                  </MenuItem>
                  <MenuItem key="style5" value="5">
                    5 Points - Team's style goes beyond the uniforms on gameday
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
 */}
              <NiceSelectFormControl fullWidth>
                <InputLabel id="style_label">Purpose Points</InputLabel>
                <Select
                  labelId="style_label"
                  id="stylepoints"
                  value={scoreCardValues.score_style_p || "0"}
                  label="Purpose Points"
                  name="score_style_p"
                  onChange={handleScorecardInputChange}
                >
                  <MenuItem key="style0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="style1" value="1">
                    1 Point - 1-10 votes
                  </MenuItem>
                  <MenuItem key="style1" value="3">
                    3 Point - 11-40 votes
                  </MenuItem>
                  <MenuItem key="style1" value="5">
                    5 Point - 41+ votes
                  </MenuItem>
                  <MenuItem key="style1" value="6">
                    6 Point with 1 bonus point for 91+ votes
                  </MenuItem>
                  <MenuItem key="style1" value="7">
                    7 Point with 2 bonus point for 141+ votes
                  </MenuItem>
                  <MenuItem key="style1" value="8">
                    8 Point with 3 bonus point for 191+ votes
                  </MenuItem>
                  <MenuItem key="style1" value="9">
                    9 Point with 4 bonus point for 241+ votes
                  </MenuItem>
                  <MenuItem key="style1" value="10">
                    10 Point with 5 bonus point for 291+ votes
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="playspirit_label">
                  Play Spirit Points
                </InputLabel>
                <Select
                  labelId="playspirit_label"
                  id="spiritpoints"
                  value={scoreCardValues.score_spirit_p || "0"}
                  label="Play Spirit Points"
                  name="score_spirit_p"
                  onChange={handleScorecardInputChange}
                >
                  <MenuItem key="spirit0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="spirit1" value="1">
                    1 Point - Chants & celebrations
                  </MenuItem>
                  <MenuItem key="spirit2" value="2">
                    2 Point
                  </MenuItem>
                  <MenuItem key="spirit3" value="3">
                    3 Points - All players showed enthusiasm
                  </MenuItem>
                  <MenuItem key="spirit4" value="4">
                    4 Point
                  </MenuItem>
                  <MenuItem key="spirit5" value="5">
                    5 Points - Becoming better and stronger
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
              <NiceSelectFormControl fullWidth>
                <InputLabel id="crowd_label">Crowd Points</InputLabel>
                <Select
                  labelId="crowd_label"
                  id="crowdpoints"
                  value={scoreCardValues.score_crowd_p || "0"}
                  label="Crowd Points"
                  name="score_crowd_p"
                  onChange={handleScorecardInputChange}
                >
                  <MenuItem key="crowd0" value="0">
                    No points given yet
                  </MenuItem>
                  <MenuItem key="crowd1" value="1">
                    1 Point - 1 to 10 new followers
                  </MenuItem>
                  <MenuItem key="crowd3" value="3">
                    3 Points - 11 to 40 new followers
                  </MenuItem>
                  <MenuItem key="crowd5" value="5">
                    5 Points -41+ new followers
                  </MenuItem>
                </Select>
              </NiceSelectFormControl>
            </div>
          )}
          <Box sx={{ m: 1, pb: 1 }}>
            <Button variant="contained" onClick={() => SaveScoreCard("Closed")}>
              Save
            </Button>
            <Button
              sx={{ ml: 2 }}
              onClick={() => closeScoreCard()}
              size="small"
            >
              Return to Scorecards
            </Button>
          </Box>
        </Container>
      </Drawer>
      {/* Team Profile*/}
      <Drawer
        PaperProps={{
          sx: { width: "100%", pr: 2 },
        }}
        anchor="right"
        open={openTeamProfile}
        onClose={toggleOpenTeamDrawer(false)}
        onOpen={toggleOpenTeamDrawer(true)}
      >
        <Box sx={{ pt: 2 }} display="flex" justifyContent="space-between">
          <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
            {teamValue.name || ""}
          </Typography>
          <CloseIcon
            fontSize="large"
            align="right"
            onClick={() => setOpenTeamProfile(false)}
          />
        </Box>
        <NiceCardMedia
          component="img"
          sx={{ width: "348px" }}
          image={
            "https://agxpxlknzr.cloudimg.io/" +
            teamValue.primary_photo__c +
            "?width=500&height=500"
          }
          alt="GGWCUP Ball"
        />
        <NiceTextField
          id="team-gg-label"
          variant="standard"
          multiline
          label={t("public_gg")}
          InputProps={{ disableUnderline: true }}
          value={lookupGG(teamValue.global_goal__c) || "..."}
        />
        <NiceCardMedia
          id="team-gg"
          component="img"
          sx={{ width: 151 }}
          image={flipGG(teamValue.global_goal__c)}
          alt="GGWCUP Ball"
        />
        <NiceTextField
          id="team-bio"
          variant="standard"
          multiline
          InputProps={{ disableUnderline: true }}
          label={t("public_bio")}
          value={teamValue.introduce_the_team_club__c || "..."}
        />
        <NiceTextField
          id="team-ggwhy"
          variant="standard"
          multiline
          InputProps={{ disableUnderline: true }}
          label={t("public_why")}
          value={teamValue.global_goal_why__c || "..."}
        />
        <NiceTextField
          id="team-ggaction"
          variant="standard"
          multiline
          InputProps={{ disableUnderline: true }}
          label={t("public_how")}
          value={teamValue.global_goal_action__c || "..."}
        />
        <NiceTextField
          id="team-success"
          variant="standard"
          multiline
          InputProps={{ disableUnderline: true }}
          label={t("public_success")}
          value={teamValue.team_success__c || "..."}
        />
        <NiceTextField
          id="team-objective"
          variant="standard"
          multiline
          InputProps={{ disableUnderline: true }}
          label={t("public_objective")}
          value={teamValue.team_objective__c || "..."}
        />
        teamValue
      </Drawer>
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Manager Portal
        </Typography>
        <NiceCheckboxFormControl
          control={
            <Checkbox
              id="checkbox-only-open-game-day"
              checked={checkedOpenEventList}
              onChange={handleCheckedOpenEventList}
              name="Invite_zoom-hcs"
            />
          }
          label="Limit lists in Games, Scores and Reports to only the open game day"
        />
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabPreview}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleTabChangePreview}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          icon={<SportsSoccerIcon />}
                          label="Events"
                          value="events"
                        />
                        <Tab
                          icon={<SportsSoccerIcon />}
                          label="Games"
                          value="games"
                        />
                        <Tab
                          icon={<SportsSoccerIcon />}
                          label="Scores"
                          value="scores"
                        />
                        <Tab
                          icon={<SportsSoccerIcon />}
                          label="Reports"
                          value="reports"
                        />
                        <Tab
                          icon={<SportsSoccerIcon />}
                          label="Teams"
                          value="teams"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="events">
                      <Typography
                        sx={{ p: 1, m: 1, pb: 0 }}
                        align="left"
                        variant="h4"
                      >
                        Events
                      </Typography>
                      <Box align="left" sx={{ m: 1, pb: 1 }}>
                        {/*                         <Button
                          variant="contained"
                          onClick={() => viewEventDetails()}
                        >
                          View
                        </Button>
                        <Button
                          sx={{ ml: 2 }}
                          variant="contained"
                          disabled={valuesViewEventCloseDisabled}
                          onClick={() => closeEvent()}
                          color="error"
                        >
                          Close
                        </Button>
 */}{" "}
                        <Button
                          sx={{ ml: 0 }}
                          variant="contained"
                          disabled={valuesViewEventOpenDisabled}
                          onClick={() => openEvent()}
                        >
                          Open
                        </Button>
                        <Button
                          sx={{ ml: 1 }}
                          variant="contained"
                          disabled={valuesViewEventVoteOpenDisabled}
                          size="small"
                          onClick={() => openLyketVoting()}
                        >
                          Open Voting
                        </Button>
                        <Button
                          sx={{ ml: 1 }}
                          variant="contained"
                          disabled={valuesViewEventVoteCloseDisabled}
                          size="small"
                          onClick={() => closeLyketVoting()}
                          color="error"
                        >
                          Close Voting
                        </Button>

                      </Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box sx={{ height: "100%", width: "100%" }}>
                            <DataGrid
                              rows={programEvents}
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              onRowClick={handleEventRowClick}
                              columns={Eventscolumns}
                              columnVisibilityModel={{
                                id: false,
                              }}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 10,
                                  },
                                },
                              }}
                              pageSizeOptions={[10, 20, 50]}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {message && <Alert severity="info">{message}</Alert>}
                    </TabPanel>
                    <TabPanel value="games">
                      <Typography
                        sx={{ p: 1, m: 1, pb: 0 }}
                        align="left"
                        variant="h4"
                      >
                        Scheduled games
                      </Typography>
                      <Box align="left" sx={{ m: 1, pb: 1 }}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => viewNewGame()}
                        >
                          New
                        </Button>
                        <Button
                          sx={{ ml: 2 }}
                          onClick={() => viewEditGame()}
                          disabled={valuesGameDeleteDisabled}
                          variant="contained"
                          size="small"
                        >
                          Edit
                        </Button>
                        <Button
                          sx={{ ml: 2 }}
                          disabled={valuesGameDeleteDisabled}
                          onClick={() => viewDeleteGame()}
                          variant="contained"
                          size="small"
                          color="error"
                        >
                          Delete
                        </Button>
                      </Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box sx={{ height: "100%", width: "100%" }}>
                            <DataGrid
                              rows={programGames}
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              onRowClick={handleScheduleRowClick}
                              columns={Schedulescolumns}
                              columnVisibilityModel={{
                                id: false,
                                team_name: false,
                                opponent_name: false,
                                game_id: false,
                                team_id: false,
                                opponent_id: false,
                              }}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 20,
                                  },
                                },
                              }}
                              pageSizeOptions={[10, 20, 50]}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {message && <Alert severity="info">{message}</Alert>}
                    </TabPanel>
                    <TabPanel value="scores">
                      <Typography
                        sx={{ p: 1, m: 1, pb: 0 }}
                        align="left"
                        variant="h4"
                      >
                        Scorecards
                      </Typography>
                      <Box align="left" sx={{ m: 1, pb: 1 }}>
                        <Button
                          sx={{ ml: 0 }}
                          disabled={valuesEditScoreDisabled}
                          variant="contained"
                          size="small"
                          onClick={() => editScorecard()}
                        >
                          Update
                        </Button>
                      </Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box sx={{ height: "100%", width: "100%" }}>
                            <DataGrid
                              rows={programEventScorecards}
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              onRowClick={handleScoreRowClick}
                              columns={Scorescolumns}
                              columnVisibilityModel={{
                                id: false,
                              }}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 25,
                                  },
                                },
                              }}
                              pageSizeOptions={[10, 25, 50]}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {message && <Alert severity="info">{message}</Alert>}
                    </TabPanel>
                    <TabPanel value="reports">
                      <Typography
                        sx={{ p: 1, m: 1, pb: 0 }}
                        align="left"
                        variant="h4"
                      >
                        Action Reports
                      </Typography>
                      <Box align="left" sx={{ m: 1, pb: 1 }}>
                        <Button
                          sx={{ ml: 0 }}
                          disabled={valuesViewARTODODisabled}
                          variant="contained"
                          size="small"
                          onClick={() => viewActionReportresponses()}
                        >
                          View
                        </Button>
                        <Button
                          disabled={valuesCloseARTODODisabled}
                          variant="contained"
                          size="small"
                          sx={{ ml: 2 }}
                          onClick={() => closeAR()}
                          color="error"
                        >
                          Close
                        </Button>
                        <Button
                          sx={{ ml: 2 }}
                          disabled={valuesRemindARTODODisabled}
                          variant="contained"
                          size="small"
                          onClick={() => remindAR()}
                        >
                          Remind
                        </Button>
                        <Button
                          sx={{ ml: 2 }}
                          disabled={valuesOpenARTODODisabled}
                          variant="contained"
                          size="small"
                          onClick={() => openAR()}
                        >
                          Open
                        </Button>
                      </Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box sx={{ height: "100%", width: "100%" }}>
                            <DataGrid
                              rows={programARTodos}
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              onRowClick={handleARRowClick}
                              columns={ARTodoscolumns}
                              columnVisibilityModel={{
                                id: false,
                              }}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 20,
                                  },
                                },
                              }}
                              pageSizeOptions={[10, 20, 50]}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {message && <Alert severity="info">{message}</Alert>}
                    </TabPanel>
                    <TabPanel value="teams">
                      <Typography
                        sx={{ p: 1, m: 1, pb: 0 }}
                        align="left"
                        variant="h4"
                      >
                        Teams
                      </Typography>
                      <Box align="left" sx={{ m: 1, pb: 1 }}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => viewTeamProfile()}
                        >
                          Team
                        </Button>
                        {/*                         <Button
                          sx={{ ml: 2 }}
                          variant="contained"
                          size="small"
                          onClick={() => viewCaptainProfile()}
                        >
                          Captain
                        </Button>
 */}{" "}
                      </Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box sx={{ height: "100%", width: "100%" }}>
                            <DataGrid
                              rows={programTeams}
                              slots={{
                                toolbar: CustomToolbar,
                              }}
                              onRowClick={handleTeamRowClick}
                              columns={Teamscolumns}
                              columnVisibilityModel={{
                                id: false,
                                team_name: true,
                                team_captain: true,
                                team_captain_email: true,
                                team_profile_status: true,
                              }}
                              initialState={{
                                pagination: {
                                  paginationModel: {
                                    pageSize: 10,
                                  },
                                },
                              }}
                              pageSizeOptions={[10, 20, 50]}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {message && <Alert severity="info">{message}</Alert>}
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default OrgPortal;
