import React from "react";
import { SidebarLayout } from "@layouts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Guard = () => {
  let navigate = useNavigate();
  const procceed = () => {
    navigate("/gate");
  };
  
  return (
    <SidebarLayout>
      <div>
        <img src="assets/sensational_league_logo.png" alt="EIR logo" width="200"></img>
        <Box>
          <Typography sx={{ pt: 3 }} variant="h5">
            Login Issue
          </Typography>
          <Typography sx={{ p: 4 }} variant="body2">
            At the moment your login credentials are not authorized to access
            the EIR App or you have used a different email address from the one
            we know you by!
          </Typography>
          <Typography variant="body2">
            Please contact us for further help
          </Typography>
          <Button onClick={procceed}>Try again</Button>
        </Box>
      </div>
    </SidebarLayout>
  );
};

export default Guard;
