import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { notifyMondayByForm } from "@lib";
import { SidebarLayout } from "@layouts";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchemaFeedback = yup.object({
  Reporter: yup.string("Provide your name").required("Reporter is required"),
  Device: yup.string("Provide the deviced used").required("Device is required"),
  Origin: yup
    .string("Provide your best guess as to where the issue originates")
    .required("Origin is required"),
  Issue: yup
    .string("Provide some details about the issue")
    .required("Issue is required"),
  Email: yup.string().email().required("Email is required"),
});

const NiceTextField = styled(TextField)({
  width: "80%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "80%",
  margin: "10px",
});

function Feedback() {
  let navigate = useNavigate();

  function SaveFeedback(values) {
    notifyMondayByForm(
      values.Issue,
      values.Origin,
      values.Email,
      values.Reporter,
      values.Device
    );
    navigate("/");
  }

  const formikEvent = useFormik({
    initialValues: {
      Reporter: "",
      Issue: "",
      Origin: "",
      Email: "",
      Device: "",
    },
    validationSchema: validationSchemaFeedback,
    onSubmit: (values) => {
      SaveFeedback(values);
    },
  });

  return (
    <SidebarLayout>
      <form onSubmit={formikEvent.handleSubmit}>
        <div>
          <img src="assets/sensational_league_logo.png" alt="EIR logo" width="200"></img>
          <Box>
            <Typography sx={{ pt: 3 }} variant="h5">
              Report Issue
            </Typography>
            <NiceTextField
              id="feedback-reporter"
              label="Reporter"
              name="Reporter"
              value={formikEvent.values.Reporter}
              onChange={formikEvent.handleChange}
              error={
                formikEvent.touched.Reporter &&
                Boolean(formikEvent.errors.Reporter)
              }
              helperText={
                formikEvent.touched.Reporter && formikEvent.errors.Reporter
              }
            />
            <NiceTextField
              id="feedback-email"
              label="User Email"
              name="Email"
              placeholder="Provide the email address of the user who is having the issue"
              value={formikEvent.values.Email}
              onChange={formikEvent.handleChange}
              error={
                formikEvent.touched.Email && Boolean(formikEvent.errors.Email)
              }
              helperText={formikEvent.touched.Email && formikEvent.errors.Email}
            />
            <NiceTextField
              id="feedback-issue"
              label="Issue"
              name="Issue"
              placeholder="Provide details about the issue with the app"
              multiline
              rows={4}
              value={formikEvent.values.Issue}
              onChange={formikEvent.handleChange}
              error={
                formikEvent.touched.Issue && Boolean(formikEvent.errors.Issue)
              }
              helperText={formikEvent.touched.Issue && formikEvent.errors.Issue}
            />
            <NiceSelectFormControl>
              <InputLabel id="feedback-origin-select-label">
                Origin of the issue
              </InputLabel>
              <Select
                labelId="feedback-origin-select-label"
                id="feedback-origin"
                label="Origin"
                name="Origin"
                value={formikEvent.values.Origin}
                onChange={formikEvent.handleChange}
                error={
                  formikEvent.touched.Origin &&
                  Boolean(formikEvent.errors.Origin)
                }
                helperText={
                  formikEvent.touched.Origin && formikEvent.errors.Origin
                }
              >
                <MenuItem key="1" value="Login">
                  Login
                </MenuItem>
                <MenuItem key="2" value="Avatar upload">
                  Avatar upload
                </MenuItem>
                <MenuItem key="3" value="Performance">
                  Perfomance
                </MenuItem>
                <MenuItem key="4" value="Misc">
                  Misc
                </MenuItem>
              </Select>
            </NiceSelectFormControl>
            <NiceSelectFormControl>
              <InputLabel id="feedback-device-select-label">Device</InputLabel>
              <Select
                labelId="feedback-device-select-label"
                id="feedback-origin"
                label="Device"
                name="Device"
                value={formikEvent.values.Device}
                onChange={formikEvent.handleChange}
                error={
                  formikEvent.touched.Device &&
                  Boolean(formikEvent.errors.Device)
                }
                helperText={
                  formikEvent.touched.Device && formikEvent.errors.Device
                }
              >
                <MenuItem key="1" value="mobile">
                  Mobile
                </MenuItem>
                <MenuItem key="2" value="web browser">
                  Web Browser
                </MenuItem>
              </Select>
            </NiceSelectFormControl>
            <Button variant="contained" type="submit">
              Send Issue
            </Button>
          </Box>
        </div>
      </form>
    </SidebarLayout>
  );
}

export default Feedback;
