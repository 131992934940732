import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "react-avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInStore } from "@redux/userSlice";
import { clearProgramInStore } from "@redux/programSlice";

const ResponsiveAppBar = () => {
  let logoPath = "assets/sensational_league_logo.png";
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const eirProgram = useSelector((state) => state.program);
  const eirUser = useSelector((state) => state.user);
  const userTeam = sessionStorage.getItem("userTeam");

  switch (eirProgram.Program_Logo__c) {
    case "GGWCUP":
      logoPath = "assets/ggwcup-logo.png";
      break;
    case "LIGA":
      logoPath = "assets/sensational_league_logo.png";
      break;
    default:
      logoPath = "assets/sensational_league_logo.png";
  }

  const logout = () => {
    sessionStorage.clear();
    dispatch(clearUserInStore());
    dispatch(clearProgramInStore());
    procceed();
  };

  const switchProgram = () => {
    navigate("/gate?context=switchProgram");
  };

  const procceed = () => {
    navigate("/home");
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              width: "40%",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          ></Box>
          <Box
            sx={{
              width: "20%",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => procceed()}
            >
              <img src={logoPath} alt="EIR Soccer" height="50px" />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem component={Link} to={"/home"} onClick={handleCloseNavMenu}>
                Dashboard
              </MenuItem>
              {eirProgram.App_Show_Library__c ? (
                <MenuItem
                  component={Link}
                  to={"/library"}
                  onClick={handleCloseNavMenu}
                >
                  Library
                </MenuItem>
              ) : (
                <div />
              )}
              <MenuItem
                component={Link}
                to={"/directory"}
                onClick={handleCloseNavMenu}
              >
                Directory
              </MenuItem>
              <Divider />
              {eirProgram.App_Show_Calendar__c ? (
                <MenuItem
                  component={Link}
                  to={"/calendar"}
                  onClick={handleCloseNavMenu}
                >
                  Calendar
                </MenuItem>
              ) : (
                <div />
              )}
              {eirProgram.App_Show_Training_Program__c ? (
                <MenuItem
                  component={Link}
                  to={"/training-programs"}
                  onClick={handleCloseNavMenu}
                >
                  Training Programs
                </MenuItem>
              ) : (
                <div />
              )}
              {userTeam === "0019N000005n02vQAA" ? (
                <div>
                  <MenuItem
                    component={Link}
                    to={"/scorecards"}
                    onClick={handleCloseNavMenu}
                  >
                    Scorecards
                  </MenuItem>
                  <Divider />
                  <MenuItem component={Link} to={"/orgportal"}>
                    Manager Portal
                  </MenuItem>
                </div>
              ) : (
                <div />
              )}
              {userTeam === "0019N000002k1ofQAA" ? (
                <div>
                <MenuItem component={Link} to={"/orgportal"}>
                Manager Portal
                </MenuItem>
              </div>
            ) : (
                <div />
              )}
              <Divider />
              <MenuItem
                component={Link}
                to={"/user"}
                onClick={handleCloseNavMenu}
              >
                Profile
              </MenuItem>
              <MenuItem
                component={Link}
                to={"/team"}
                onClick={handleCloseNavMenu}
              >
                Team Profile
              </MenuItem>
              {/*               <MenuItem
                component={Link}
                to={"/roster"}
                onClick={handleCloseNavMenu}
              >
                Team Roster
              </MenuItem>
 */}{" "}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={procceed}
            >
              <img src={logoPath} alt="EIR Soccer" height="50px" />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "40%",
              flexGrow: 0,
              justifyContent: "right",
              alignItems: "right",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Button
              component={Link}
              to={"/home"}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "black", display: "block" }}
            >
              Dashboard
            </Button>
            {eirProgram.App_Show_Library__c ? (
              <Button
                component={Link}
                to={"/library"}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                Library
              </Button>
            ) : (
              <div />
            )}
            <Button
              component={Link}
              to={"/directory"}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "black", display: "block" }}
            >
              Directory
            </Button>
            <Button
              onClick={logout}
              sx={{ my: 2, color: "black", display: "block" }}
            >
              Logout
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  name={eirUser.userName}
                  size="40"
                  round={true}
                  src={
                    "https://agxpxlknzr.cloudimg.io/" +
                    eirUser.Avatar__c +
                    "?width=40&height=40"
                  }
                  color={Avatar.getRandomColor("sitebase", ["red", "green"])}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={switchProgram}>Switch Program</MenuItem>
              <Divider />
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
