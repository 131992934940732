import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const validationSchemaEvent = yup.object({
});

function ImpactLeagueReportSponsor() {
  let navigate = useNavigate();
  const [todoAR, setTodoAR] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [showAltQ, setShowAltQ] = useState(false);

  const [searchParams] = useSearchParams();
  const theARId = searchParams.get("id");

  const GetTodo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
      theARId,
      requestOptions
    );
    const data = await response.json();
    try {
      setTodoAR(data);
      if (data.Payload__c == "True") {
        setShowAltQ(true);
      }
      console.log(showAltQ);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetTodo();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const SaveResponse = async (theValue, theDisplay, theQuestion, theType) => {
    var theJSON = {
      Display_Value__c: theDisplay,
      EIR_Action_Question__c: theQuestion,
      EIR_Action_Todo__c: todoAR.Id,
      App_User__c: todoAR.App_Data_Manager__c,
    };

    if (theType === "Boolean") {
      if (theValue === "True") {
        theJSON.Value_as_Boolean__c = true;
      } else {
        theJSON.Value_as_Boolean__c = false;
      }
    }
    if (theType === "String") {
      theJSON.Value_as_String__c = theValue;
    }
    if (theType === "Number") {
      theJSON.Value_as_Number__c = theValue;
    }
    if (theType === "Id") {
      theJSON.Value_as_ID__c = theValue;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Response__c/",
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      //toast("Response created");
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateTodoAR = async () => {
    var theJSON = {
      Submit_Date__c: today,
      State__c: "Submitted",
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(theJSON, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
      todoAR.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("Action Report submited"))
      .then((result) => navigate("/home"))
      .catch((error) => console.log("error", error));
  };

  const today = new Date();

  const formikEvent = useFormik({
    initialValues: {
      AQ0024: "",
    },
    validationSchema: validationSchemaEvent,
    onSubmit: (values) => {
      setSubmited(true);
      //alert(JSON.stringify(values, null, 2));
      if (showAltQ) {
        SaveResponse(
          values.AQ0026,
          values.AQ0026,
          "a2D9N0000005iDHUAY",
          "Boolean"
        );
  
        if (values.AQ0025 !== "") {
          SaveResponse(
            values.AQ0025,
            values.AQ0025,
            "a2D9N0000005fPIUAY",
            "String"
          );
        }
  
      } else {
        SaveResponse(
          values.AQ0024,
          values.AQ0024,
          "a2D9N0000005fPDUAY",
          "Boolean"
        );
  
        if (values.AQ0025 !== "") {
          SaveResponse(
            values.AQ0025,
            values.AQ0025,
            "a2D9N0000005fPIUAY",
            "String"
          );
        }
  
      }
      UpdateTodoAR();
    },
  });

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form onSubmit={formikEvent.handleSubmit}>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h4"
                  component="div"
                >
                  SENSATIONAL LEAGUE PARTNERSHIP REPORT
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Time to tell us about your partnership/sponsor activities.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  This should take no more than 3 to 5 minutes to complete.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Thank you.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  Title: {todoAR.Title__c}
                </Typography>
                {showAltQ === true ? (
                  <div>
                {/* AQ0026 - Boolean */}
                <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q1: How has your relationship with your team sponsor evolved since last game day? Please share what you are most proud of achieving together?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0026-select-label">Yes or No</InputLabel>
                      <Select
                        labelId="AQ0026-select-label"
                        id="AQ0026"
                        label="Yes or No"
                        name="AQ0026"
                        disabled={submited}
                        value={formikEvent.values.AQ0026 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0003 &&
                          Boolean(formikEvent.errors.AQ0026)
                        }
                        helperText={
                          formikEvent.touched.AQ0026 && formikEvent.errors.AQ0026
                        }
                      >
                        <MenuItem key="True" value="True">
                        Yes, the partnership is great
                        </MenuItem>
                        <MenuItem key="False" value="False">
                        No, the partnership has stopped
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0027 - 4row text */}
                      <div>
                        <Typography
                          sx={{ pt: 1 }}
                          gutterBottom
                          variant="body2"
                          component="div"
                        >
                          Q2 Could you tell us more about the partnership?
                        </Typography>
                        <NiceTextField
                          id="AQ0025-message"
                          label="Feedback"
                          name="AQ0025"
                          disabled={submited}
                          placeholder="General Feedback"
                          multiline
                          rows={4}
                          value={formikEvent.values.AQ0025 || ""}
                          onChange={formikEvent.handleChange}
                          error={
                            formikEvent.touched.AQ0025 &&
                            Boolean(formikEvent.errors.AQ0025)
                          }
                          helperText={
                            formikEvent.touched.AQ0025 && formikEvent.errors.AQ0025
                          }
                        />
                      </div>
                  </div>
                ) : (
                  <div>

                {/* AQ0024 - Boolean */}
                    <Typography
                      sx={{ pt: 1 }}
                      gutterBottom
                      variant="body2"
                      component="div"
                    >
                      Q1: Since the last game day, did you connect with a partner/sponsor for your team and/or Purpose Project?
                    </Typography>
                    <NiceSelectFormControl fullWidth>
                      <InputLabel id="AQ0024-select-label">Yes or No</InputLabel>
                      <Select
                        labelId="AQ0024-select-label"
                        id="AQ0024"
                        label="Yes or No"
                        name="AQ0024"
                        disabled={submited}
                        value={formikEvent.values.AQ0024 || ""}
                        onChange={formikEvent.handleChange}
                        error={
                          formikEvent.touched.AQ0003 &&
                          Boolean(formikEvent.errors.AQ0024)
                        }
                        helperText={
                          formikEvent.touched.AQ0024 && formikEvent.errors.AQ0024
                        }
                      >
                        <MenuItem key="True" value="True">
                          Yes
                        </MenuItem>
                        <MenuItem key="False" value="False">
                          No
                        </MenuItem>
                      </Select>
                    </NiceSelectFormControl>
                    {/* AQ0025 - 4row text */}
                    {formikEvent.values.AQ0024 === "True" ? (
                      <div>
                        <Typography
                          sx={{ pt: 1 }}
                          gutterBottom
                          variant="body2"
                          component="div"
                        >
                          Q2 could you tell us more about the partnership?
                        </Typography>
                        <NiceTextField
                          id="AQ0025-message"
                          label="Feedback"
                          name="AQ0025"
                          disabled={submited}
                          placeholder="General Feedback"
                          multiline
                          rows={4}
                          value={formikEvent.values.AQ0025 || ""}
                          onChange={formikEvent.handleChange}
                          error={
                            formikEvent.touched.AQ0025 &&
                            Boolean(formikEvent.errors.AQ0025)
                          }
                          helperText={
                            formikEvent.touched.AQ0025 && formikEvent.errors.AQ0025
                          }
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button variant="contained" type="submit" disabled={submited}>
                  SUBMIT
                </Button>
                <Button
                  sx={{ pl: 4 }}
                  component={Link}
                  to={"/home"}
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default ImpactLeagueReportSponsor;
