/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

const Profile = () => {
  
};
export default () => ({
    auth: {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      redirect: process.env.REACT_APP_AUTH0_REDIRECT,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      secret: process.env.REACT_APP_AUTH0_SECRET,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    }
  });  