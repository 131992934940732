import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { CssBaseline } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import "@core/styles.css";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => (
  <React.Fragment>
    <Helmet>
      <title>Sensational League</title>
      //favicon
      <link rel="icon" type="image/x-icon" href="https://images.squarespace-cdn.com/content/v1/63f8eb5a81ef74363b09bd4d/86dc6589-8be8-4173-a337-7e1a7dd6aab3/favicon.ico?format=100w"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/ImpactLeague_logo_180x180.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="https://images.squarespace-cdn.com/content/v1/63f8eb5a81ef74363b09bd4d/86dc6589-8be8-4173-a337-7e1a7dd6aab3/favicon.ico" />
      <link rel="icon" type="image/png" sizes="16x16" href="https://images.squarespace-cdn.com/content/v1/63f8eb5a81ef74363b09bd4d/86dc6589-8be8-4173-a337-7e1a7dd6aab3/favicon.ico" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00a300" />
      <meta name="theme-color" content="#ffffff"></meta>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Public+Sans:300,400,500,700&display=swap"/>
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.8.0/react-datepicker.min.css" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
    </Helmet>
    <CssBaseline />
    <div>{children}</div>

    <ToastContainer autoClose={3000} />
  </React.Fragment>
);
