import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceSelectFormControl = styled(FormControl)({
  width: "95%",
  margin: "10px",
});

const validationSchemaEvent = yup.object({
  AQ0001: yup.number("Enter a number").required("Is required"),
  AQ0004: yup.number().required(),
  AQ0013: yup.number().required(),
  AQ0018: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0019: yup
    .number()
    .when("AQ0013", { is: true, then: yup.number().required() }),
  AQ0021: yup.number().required(),
});

function ImpactLeagueReportCaptain() {
  let navigate = useNavigate();
  const [todoAR, setTodoAR] = useState([]);
  const [submited, setSubmited] = useState(false);

  const [searchParams] = useSearchParams();
  const theARId = searchParams.get("id");

  const GetTodo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
      theARId,
      requestOptions
    );
    const data = await response.json();
    try {
      setTodoAR(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetTodo();
  }, []);

  const sftoken = sessionStorage.getItem("sftoken");

  const SaveResponse = async (theValue, theDisplay, theQuestion, theType) => {
    var theJSON = {
      Display_Value__c: theDisplay,
      EIR_Action_Question__c: theQuestion,
      EIR_Action_Todo__c: todoAR.Id,
      App_User__c: todoAR.App_Data_Manager__c,
    };

    if (theType === "Boolean") {
      if (theValue === "True") {
        theJSON.Value_as_Boolean__c = true;
      } else {
        theJSON.Value_as_Boolean__c = false;
      }
    }
    if (theType === "String") {
      theJSON.Value_as_String__c = theValue;
    }
    if (theType === "Number") {
      theJSON.Value_as_Number__c = theValue;
    }
    if (theType === "Id") {
      theJSON.Value_as_ID__c = theValue;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Response__c/",
      requestOptions
    );
    const data = await response.json();
    //console.log("Data= ", data);
    try {
      //toast("Response created");
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateTodoAR = async () => {
    var theJSON = {
      Submit_Date__c: today,
      State__c: "Submitted",
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(theJSON, null, 2),
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/EIR_Action_Todo__c/" +
      todoAR.Id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => toast("Action Report submited"))
      .then((result) => navigate("/home"))
      .catch((error) => console.log("error", error));
  };

  const today = new Date();

  const formikEvent = useFormik({
    initialValues: {
      AQ0001: "",
      AQ0004: "",
      AQ0013: "",
      AQ0018: "",
      AQ0019: "",
      AQ0021: "",
    },
    validationSchema: validationSchemaEvent,
    onSubmit: (values) => {
      setSubmited(true);
      //alert(JSON.stringify(values, null, 2));
      SaveResponse(
        values.AQ0001,
        values.AQ0001,
        "a2D090000004OVDEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0004,
        values.AQ0004,
        "a2D090000004OVNEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0013,
        values.AQ0013,
        "a2D090000004OVYEA2",
        "Number"
      );
      SaveResponse(
        values.AQ0018,
        values.AQ0018,
        "a2D090000004OVhEAM",
        "Number"
      );
      SaveResponse(
        values.AQ0019,
        values.AQ0019,
        "a2D090000004OVmEAM",
        "Number"
      );
      SaveResponse(
        values.AQ0021,
        values.AQ0021,
        "a2D090000004OVsEAM",
        "Number"
      );
      UpdateTodoAR();
    },
  });

  return (
    <AuthenticatedLayout title="Profile">
      <Container maxWidth="lg">
        <form onSubmit={formikEvent.handleSubmit}>
          <Grid align="left" container spacing={2}>
            <Grid item>
              <div>
                <Typography
                  sx={{ p: 1 }}
                  gutterBottom
                  variant="h4"
                  component="div"
                >
                  SENSATIONAL LEAGUE ACTION REPORT
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Time to track and add your activities to the data pool.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  This should take no more than 5 to 10 minutes to complete.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Thank you.
                </Typography>
                <Typography
                  sx={{ pt: 1, pl: 2 }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  Title: {todoAR.Title__c}
                </Typography>
                {/* AQ0004 - Select - Minutes */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q1: How many hours have you trained with your team since the
                  last game day?
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0004-select-label">
                    Duration (in minutes)
                  </InputLabel>
                  <Select
                    labelId="AQ0004-select-label"
                    id="AQ0004"
                    label="Duration (in minutes)"
                    name="AQ0004"
                    disabled={submited}
                    value={formikEvent.values.AQ0004 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0004 &&
                      Boolean(formikEvent.errors.AQ0004)
                    }
                    helperText={
                      formikEvent.touched.AQ0004 && formikEvent.errors.AQ0004
                    }
                  >
                    <MenuItem key="0" value="0">
                      0 minutes
                    </MenuItem>
                    <MenuItem key="30" value="30">
                      30 minutes
                    </MenuItem>
                    <MenuItem key="60" value="60">
                      1 hour
                    </MenuItem>
                    <MenuItem key="90" value="90">
                      1 hour and 30 minutes
                    </MenuItem>
                    <MenuItem key="120" value="120">
                      2 hours
                    </MenuItem>
                    <MenuItem key="180" value="180">
                      3 hours
                    </MenuItem>
                    <MenuItem key="240" value="240">
                      4 hours
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0013 - Number */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q2: Since the last game day, how many community engagement activities did the team host or lead? 
                  Please include activities hosted/lead by individual players.?
                </Typography>
                <NiceTextField
                  id="AQ0013-number"
                  label="Number of activities"
                  name="AQ0013"
                  value={formikEvent.values.AQ0013 || ""}
                  disabled={submited}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0013 &&
                    Boolean(formikEvent.errors.AQ0013)
                  }
                  helperText={
                    formikEvent.touched.AQ0013 && formikEvent.errors.AQ0013
                  }
                />
                {/* AQ0018 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q3: Since the last game day, how many guests participated in any of the team's community engagement activities?
                </Typography>
                <NiceTextField
                  id="AQ0018-number"
                  label="Number of people"
                  name="AQ0018"
                  disabled={submited}
                  value={formikEvent.values.AQ0018 || ""}
                  onChange={formikEvent.handleChange}
                  error={
                    formikEvent.touched.AQ0018 &&
                    Boolean(formikEvent.errors.AQ0018)
                  }
                  helperText={
                    formikEvent.touched.AQ0018 && formikEvent.errors.AQ0018
                  }
                />
                {/* AQ0019 - Select - Minutes */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q4: Since the last game day, how many hours total did the team spend on the team's community engagement activities? (Please include planning)
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0019-select-label">
                    Duration (in minutes)
                  </InputLabel>
                  <Select
                    labelId="AQ0019-select-label"
                    id="AQ0019"
                    label="Duration (in minutes)"
                    name="AQ0019"
                    disabled={submited}
                    value={formikEvent.values.AQ0019 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0019 &&
                      Boolean(formikEvent.errors.AQ0019)
                    }
                    helperText={
                      formikEvent.touched.AQ0019 && formikEvent.errors.AQ0019
                    }
                  >
                    <MenuItem key="0" value="0">
                      0 minutes
                    </MenuItem>
                    <MenuItem key="30" value="30">
                      30 minutes
                    </MenuItem>
                    <MenuItem key="60" value="60">
                      1 hour
                    </MenuItem>
                    <MenuItem key="90" value="90">
                      1 hour and 30 minutes
                    </MenuItem>
                    <MenuItem key="120" value="120">
                      2 hours
                    </MenuItem>
                    <MenuItem key="180" value="180">
                      3 hours
                    </MenuItem>
                    <MenuItem key="240" value="240">
                      4 hours
                    </MenuItem>
                    <MenuItem key="300" value="300">
                      5 hours
                    </MenuItem>
                    <MenuItem key="360" value="360">
                      6 hours
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0021 - Select - NPS - 1-10 */}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q5: From your perspective, how likely is it that the activities inspired others to act?
                </Typography>
                <NiceSelectFormControl fullWidth>
                  <InputLabel id="AQ0021-select-label">
                    1 - 10
                  </InputLabel>
                  <Select
                    labelId="AQ0021-select-label"
                    id="AQ0021"
                    label="Score"
                    name="AQ0021"
                    disabled={submited}
                    value={formikEvent.values.AQ0021 || ""}
                    onChange={formikEvent.handleChange}
                    error={
                      formikEvent.touched.AQ0021 &&
                      Boolean(formikEvent.errors.AQ0021)
                    }
                    helperText={
                      formikEvent.touched.AQ0021 && formikEvent.errors.AQ0021
                    }
                  >
                    <MenuItem key="1" value="1">
                      1 (lowest)
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      2
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      3
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      4
                    </MenuItem>
                    <MenuItem key="5" value="5">
                      5
                    </MenuItem>
                    <MenuItem key="6" value="6">
                      6
                    </MenuItem>
                    <MenuItem key="7" value="7">
                      7
                    </MenuItem>
                    <MenuItem key="8" value="8">
                      8
                    </MenuItem>
                    <MenuItem key="9" value="9">
                      9
                    </MenuItem>
                    <MenuItem key="10" value="10">
                      10 (highest)
                    </MenuItem>
                  </Select>
                </NiceSelectFormControl>
                {/* AQ0001 - Number*/}
                <Typography
                  sx={{ pt: 1 }}
                  gutterBottom
                  variant="body2"
                  component="div"
                >
                  Q6: How many followers does the team have on the team's Instagram profile today? 
                </Typography>
                <NiceTextField
                  id="AQ0001"
                  label="Number of people"
                  name="AQ0001"
                  value={formikEvent.values.AQ0001 || ""}
                  onChange={formikEvent.handleChange}
                  disabled={submited}
                  error={
                    formikEvent.touched.AQ0001 &&
                    Boolean(formikEvent.errors.AQ0001)
                  }
                  helperText={
                    formikEvent.touched.AQ0001 && formikEvent.errors.AQ0001
                  }
                />
              </div>
              <Box sx={{ m: 1, pb: 4 }}>
                <Button variant="contained" type="submit" disabled={submited}>
                  SUBMIT
                </Button>
                <Button
                  sx={{ pl: 4 }}
                  component={Link}
                  to={"/home"}
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </AuthenticatedLayout>
  );
}

export default ImpactLeagueReportCaptain;
