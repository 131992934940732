import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth/use-auth";
import { ConfirmProvider } from "material-ui-confirm";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import "./i18n";
import store from "./redux/store";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <ConfirmProvider>
            <App />
        </ConfirmProvider>
      </AuthProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

ReactGA.initialize("G-96LVM9N21R");
ReactGA.send("pageview");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
