import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Container, Box, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "react-avatar";
import { UniqueArray } from "@lib";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import CardMedia from "@mui/material/CardMedia";

const EmailButton = styled(Button)({
  textTransform: "none",
  padding: 0,
});

const NiceTextField = styled(TextField)({
  width: "95%",
  margin: "10px",
});

const NiceCardMedia = styled(CardMedia)({
  margin: "10px",
});

function Roster() {
  let navigate = useNavigate();

  const [openPlayerPreview, setOpenPlayerPreview] = useState(false);
  const [openTeamPreview, setOpenTeamPreview] = useState(false);

  const theProgramID = sessionStorage.getItem("userProgram");

  const [rawData, setRawData] = useState([]);
  const [values, setValues] = useState([]);
  const [listCounter, setListCounter] = useState(0);

  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [valuesPlayerPreview, setValuesPlayerPreview] = useState([]);
  const [valuesTeamPreview, setValuesTeamPreview] = useState([]);

  const [members, setMembers] = useState([]);

  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);

  const [group, setGroup] = useState("");

  const [searcName, setSearcName] = useState("");

  const sftoken = sessionStorage.getItem("sftoken");

  const userTeam = sessionStorage.getItem("userTeam");


  function flipGG(theGG) {
    var theSource = "assets/ggwcup-ball.png";
    if (theGG) {
      theSource = "assets/" + theGG + ".png";
    }
    return theSource;
  }

  function viewPlayer(theID) {
    GetPlayer(theID);
    setOpenPlayerPreview(true);
  }

  function viewTeam(theID) {
    GetTeam(theID);
    setOpenTeamPreview(true);
  }

  const togglePlayerPreview = (newOpen) => () => {
    setOpenPlayerPreview(newOpen);
  };

  const toggleTeamPreview = (newOpen) => () => {
    setOpenTeamPreview(newOpen);
  };

  useEffect(() => {
    FetchMembers(userTeam);
  }, []);

  const FetchMembers = async (theTeam) => {
    //console.log("eirMembers and NOT InStore=", eirMembers);
    //const theURL = "http://localhost:5000/teamMembers/" + theTeam;
    const theURL =
      "https://backend-app-9ofqo.ondigitalocean.app/teamMembers/" + theTeam;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      setMembers(data);
      setListCounter(data.length);
    } catch (err) {
      console.log(err);
    }
  };

  const GetTeam = async (theID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Account/" +
        theID,
      requestOptions
    );
    const data = await response.json();
    try {
      setValuesTeamPreview(data);
    } catch (err) {
      console.log(err);
    }
  };

  const GetPlayer = async (theID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
        theID,
      requestOptions
    );
    const data = await response.json();
    try {
      setValuesPlayerPreview(data);
    } catch (err) {
      console.log(err);
    }
  };

  function teamProfile(obj) {
    if (obj.team_profile_status === "Published") {
      return (
        <MuiLink
          component="button"
          onClick={() => viewTeam(obj.team_id)}
          variant="body1"
          underline="hover"
        >
          {obj.team_name}
        </MuiLink>
      );
    } else {
      return obj.team_name;
    }
  }

  return (
    <AuthenticatedLayout title="Directory">
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "66%",
              xl: "66%",
            },
          },
        }}
        anchor="right"
        open={openPlayerPreview}
        onClose={togglePlayerPreview(false)}
        onOpen={togglePlayerPreview(true)}
      >
        <Container>
          {" "}
          <Box
            sx={{ pt: 2, pr: 2 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              {valuesPlayerPreview.Name || ""}
            </Typography>

            <CloseIcon
              fontSize="large"
              align="right"
              onClick={togglePlayerPreview(false)}
            />
          </Box>
          <form>
            <Grid align="left" container spacing={2}>
              <Grid item>
                <div>
                  <div>
                    <Box sx={{ p: 1, pt: 3 }}>
                      <Avatar
                        name={valuesPlayerPreview.Name || "XX XX"}
                        size="150"
                        src={
                          "https://agxpxlknzr.cloudimg.io/" +
                          valuesPlayerPreview.Avatar__c +
                          "?width=150&height=150"
                        }
                        color={Avatar.getRandomColor("sitebase", [
                          "red",
                          "blue",
                        ])}
                      />
                    </Box>
                  </div>
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    PERSONAL DETAILS
                  </Typography>
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-role"
                    label="Role"
                    value={valuesPlayerPreview.contact_type || ""}
                    variant="standard"
                  />
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-team"
                    label="Team"
                    value={valuesPlayerPreview.Affiliated_Team__c || ""}
                    variant="standard"
                  />
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-firstname"
                    label="First Name"
                    value={valuesPlayerPreview.FirstName || ""}
                    name="FirstName"
                    variant="standard"
                  />
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-lastname"
                    label="Last Name"
                    value={valuesPlayerPreview.LastName || ""}
                    name="LastName"
                    variant="standard"
                  />
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-email"
                    label="Email"
                    value={valuesPlayerPreview.Email || ""}
                    name="Email"
                    variant="standard"
                  />
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-phone"
                    label="Mobile Phone"
                    value={valuesPlayerPreview.MobilePhone || ""}
                    name="MobilePhone"
                    variant="standard"
                  />
                  <NiceCardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={flipGG(valuesPlayerPreview.Global_Goal__c)}
                    alt="GGWCUP Ball"
                  />
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    OTHER DETAILS
                  </Typography>
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-bio"
                    label="Bio"
                    value={valuesPlayerPreview.Bio__c || ""}
                    name="Bio__c"
                    multiline
                    rows={4}
                    variant="standard"
                  />
                  <NiceTextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="profile-philosophy"
                    label="Play Philosophy"
                    value={valuesPlayerPreview.Play_Philosophy__c || ""}
                    name="Play_Philosophy__c"
                    multiline
                    rows={4}
                    variant="standard"
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "66%",
              xl: "66%",
            },
          },
        }}
        anchor="right"
        open={openTeamPreview}
        onClose={toggleTeamPreview(false)}
        onOpen={toggleTeamPreview(true)}
      >
        <Container>
          {" "}
          <Box
            sx={{ pt: 2, pr: 2 }}
            display="flex"
            justifyContent="space-between"
          >
            <Typography align="left" variant="h4">
              {valuesTeamPreview.Name || ""}
            </Typography>

            <CloseIcon
              fontSize="large"
              align="right"
              onClick={toggleTeamPreview(false)}
            />
          </Box>
          <form>
            <Grid align="left" container spacing={2}>
              <Grid item>
                <div>
                  <Box sx={{ p: 1, pt: 3 }}>
                    <Avatar
                      name={valuesTeamPreview.Name || "XX XX"}
                      size="250"
                      src={
                        "https://agxpxlknzr.cloudimg.io/" +
                        valuesTeamPreview.Primary_Photo__c +
                        "?width=250&height=250"
                      }
                      color={Avatar.getRandomColor("sitebase", ["red", "blue"])}
                    />
                  </Box>

                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    TEAM DETAILS
                  </Typography>
                  <NiceTextField
                    id="profile-teamname"
                    label="Team Name"
                    value={valuesTeamPreview.Name || ""}
                    name="Name"
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-bio"
                    label="Team Bio"
                    value={valuesTeamPreview.Introduce_the_team_club__c || ""}
                    name="Introduce_the_team_club__c"
                    multiline
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceCardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={flipGG(valuesTeamPreview.Global_Goal__c)}
                    alt="GGWCUP Ball"
                  />
                  <NiceTextField
                    id="profile-whyGG"
                    label="Why is this Goal important to the Team?"
                    value={valuesTeamPreview.Global_Goal_Why__c || ""}
                    name="Global_Goal_Why__c"
                    multiline
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-actionGG"
                    label="How will the Team take action for the Goal?"
                    value={valuesTeamPreview.Global_Goal_Action__c || ""}
                    name="Global_Goal_Action__c"
                    multiline
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-impactGG"
                    label="How will the Team measure impact and reach?"
                    value={valuesTeamPreview.Global_Goal_Impact__c || ""}
                    name="Global_Goal_Impact__c"
                    multiline
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-fb-handle"
                    label="Facebook handle?"
                    value={valuesTeamPreview.Facebook_handle__c || ""}
                    name="Facebook_handle__c"
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-instergram-handle"
                    label="Instagram handle?"
                    value={valuesTeamPreview.Instragram_handle__c || ""}
                    name="Instragram_handle__c"
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-twitter-handle"
                    label="Twitter handle?"
                    value={valuesTeamPreview.Twitter_handle__c || ""}
                    name="Twitter_handle__c"
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <NiceTextField
                    id="profile-website-handle"
                    label="Website URL?"
                    value={valuesTeamPreview.Website || ""}
                    name="Website"
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                  <Typography
                    sx={{ p: 1 }}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    Team administration
                  </Typography>
                  <NiceTextField
                    id="profile-data-manager"
                    label="Data Manager"
                    value={valuesTeamPreview.App_Data_Manager_Name__c || ""}
                    name="App_Data_Manager_Name__c"
                    variant="standard"
                    InputProps={{
                      readOnly: Boolean(true),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Drawer>
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Team Roster
        </Typography>
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ pl:0, pr:1 }}>#{listCounter}</TableCell>
                        <TableCell sx={{ pl:0, pr:0 }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {members.map((row) => (
                        <TableRow
                          key={row.Id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ pl:0, pr:1 }}>
                            <Avatar
                              name={row.name}
                              size="38"
                              src={
                                "https://agxpxlknzr.cloudimg.io/" +
                                row.avatar__c +
                                "?width=38&height=38&func=face"
                              }
                            />
                          </TableCell>
                          <TableCell sx={{ pl:0, pr:0 }}>
                            <Typography
                              align="left"
                              sx={{ pb: 0 }}
                              noWrap
                              variant="body1"
                            >
                              <MuiLink
                                component="button"
                                onClick={() => viewPlayer(row.id)}
                                variant="body1"
                                underline="hover"
                              >
                                {row.name}
                              </MuiLink>
                            </Typography>
                            <Typography noWrap align="left" variant="caption">
                              {row.userprofile__c}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

export default Roster;
