import React, { useEffect, useState } from "react";
import { useAuth } from "@auth";
import jwtDecode from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { notifyMonday } from "@lib";

const OnBoarding = () => {
  let navigate = useNavigate();
  let { setAuthenticated } = useAuth();

  const { hash } = useLocation();
  const params = new URLSearchParams(hash);
  const jwt = jwtDecode(params.get("id_token"));
  const sfdemouser = jwt["email"];
  const sftoken = jwt["http://ggwcup.com/sftoken"];
  const [error, setError] = useState(false);
  const [activeTeams, setActiveTeams] = useState([]);

  useEffect(() => {
    async function checkOnboarding() {
      try {
        FetchActiveTeams(sfdemouser);
        setAuthenticated(true);
        IncrementLogins();
        sessionStorage.setItem("authenticated", "true");
        if (sfdemouser === "demo@eirsoccer.com") {
          sessionStorage.setItem("demoUser", true);
        } else {
          sessionStorage.setItem("demoUser", false);
        }
        sessionStorage.setItem("authResultPayload", JSON.stringify(jwt));
        navigate("/");
      } catch (e) {
        notifyMonday("Issue with onboarding","checkOnboarding()");
        setError(true);
        console.log(e.message, e.stack, "message here");
      }
    }
    checkOnboarding();
  });

  const FetchActiveTeams = async (theId) => {
    const theURL = "https://backend-app-9ofqo.ondigitalocean.app/affiliatedTeamsByAuth0Email/" + theId;
    const response = await fetch(theURL);
    const data = await response.json();
    try {
      //console.log("data=", data);
      setActiveTeams(data);
      console.log("Active Teams= ", data);

    } catch (err) {
      console.log(err);
    }
  };

  function IncrementLogins() {
    var today = new Date();
    var theJSON = {
      Auth0_Last_Login__c: today,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/Auth0_Email__c/" + sfdemouser,
      requestOptions
    )
      .then((response) => response.text())
      .catch((error) => notifyMonday(error,"IncrementLogins()",sfdemouser,"The App"));
  }

  const procceed = () => {
    navigate("/");
  };

  const Error = () => {
    return (
      <>
        <div>
          <h1 style={{ fontSize: "12px", color: "red" }}>
            Problem with onboarding. Please try signup again
          </h1>
          <Button onClick={procceed}>Procceed without onboarding</Button>
        </div>
      </>
    );
  };

  const Loading = () => {
    return (
      <>
        <div>
          <h1 style={{ fontSize: "12px", color: "gray" }}>
            Checking onboarding setup ...
          </h1>
        </div>
      </>
    );
  };

  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {error ? <Error /> : <Loading />}
      </div>
    </div>
  );
};

export default OnBoarding;
