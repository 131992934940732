import React from "react";
import Box from "@mui/material/Box";
import Document from "./Document";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => (
  <Document>
    <Box sx={{ p:2, pt:1, pb:2, backgroundColor: "#FFF", flexGrow: 1 }}>
      <img
        src="assets/sensational_league_logo.png"
        alt="Sensational League Close The Gap"
        height="120px"
      />
    </Box>
    <Box sx={{ height: "50px", backgroundColor: "#F3A328 ", flexGrow: 1 }}>
    </Box>
    {children}
  </Document>
);
