import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import getCroppedImg from "@lib/cropImage";
import AuthenticatedLayout from "@layouts/AuthenticatedLayout";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import S3FileUpload from "react-s3";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; 
import { notifyMonday } from "@lib";

const RelativeBox = styled(Box)({
  position: "relative",
  width: "100%",
  height: 400,
  background: "#fff",
});

const Input = styled("input")({
  display: "none",
});

const config = {
  bucketName: "ggwcup-user-uploads",
  region: "eu-central-1",
  s3Url: "https://ggwcup-user-uploads.s3.amazonaws.com/",
  accessKeyId: "AKIATSRJPHIEDYLURHM5",
  secretAccessKey: "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",
};

function CropAvatar() {
  const sftoken = sessionStorage.getItem("sftoken");
  const sfdemouser = sessionStorage.getItem("demoUser");

  const [searchParams] = useSearchParams();
  const theOriginalImageSrc = searchParams.get("src");
  const theContactID = searchParams.get("id");

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  function avatarUpload() {
    if (sfdemouser === "true") {
      toast("Avatar is not saved because this is a demo session!");
      return;
    }
    setSaveButtonDisabled(true);
    toast("Avatar being uploaded to the cloud, please wait");
    prepareCroppedImage();
  }

  function saveAvatar(theURL) {
    var theJSON = {
      Avatar__c: theURL,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + sftoken);

    var raw = JSON.stringify(theJSON);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://eirorg2.my.salesforce.com/services/data/v21.0/sobjects/Contact/" +
        theContactID,
      requestOptions
    )
      .then((response) => response.text())
      .then((response) => setSaveButtonDisabled(false))
      .then((result) => toast("Avatar uploaded"))
      .catch((error) => notifyMonday(error,"saveAvatar()"));
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    //setSaveButtonDisabled(false);
  }, []);

  const prepareCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImage);

      croppedImage.toBlob(function (blob) {
        var filename = uuidv4() + ".png";
        var file = new File([blob], filename, { type: "image/png" });
        S3FileUpload.uploadFile(file, config)
          .then((data) => {
            //console.log("file= ", data.location);
            saveAvatar(data.location);
          })
          .catch((error) => notifyMonday(error,"S3FileUpload()"));
      });
    } catch (e) {
      console.error(e);
    }
  });

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setSaveButtonDisabled(false);
      setImageSrc(imageDataUrl);
    }
  };

  useEffect(() => {
    if (theOriginalImageSrc === "") {
      setImageSrc("assets/ggwcup-ball.png");
      setSaveButtonDisabled(false);
    } else {
      setImageSrc(theOriginalImageSrc);
    }
  }, []);

  return (
    <AuthenticatedLayout title="Directory">
      <Container>
        <Typography sx={{ p: 1, m: 1, pb: 0 }} align="left" variant="h4">
          Update your avatar
        </Typography>
        <div>
          <React.Fragment>
            <RelativeBox>
              <Cropper
                image={imageSrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={2 / 2}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </RelativeBox>
            <div>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={onFileChange}
                />
                <Button
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Select new avatar
                </Button>
              </label>
              <div>
                <Typography sx={{ mt: 2, pb: 0 }} variant="h6">
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.2}
                  aria-labelledby="Zoom"
                  onChange={
                    (e, zoom) => {
                      setZoom(zoom)
                      setSaveButtonDisabled(false);
                    }
                  }
                />
              </div>

              <Button
                onClick={avatarUpload}
                disabled={saveButtonDisabled}
                variant="contained"
                color="primary"
              >
                Save avatar
              </Button>
              <Button sx={{ pl: 4 }} component={Link} to={"/home"} size="small">
                  Return to Dashboard
                </Button>
            </div>
          </React.Fragment>
        </div>
      </Container>
    </AuthenticatedLayout>
  );
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default CropAvatar;
